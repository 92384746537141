<template>
  <header>
    <div class="nav-background">
	    <Navbar />
      <div class="container-xxl">
        <div class="row d-flex justify-content-around mt-1">
          <div class="col-12 col-md-6">
            <h1 class="text-light display-1 text-center text-lg-start">Welcome to <br />Inside <span class="yellow-heading">CKD</span></h1>
            <p class="text-light pt-3 text-center text-lg-start homepage-paras">This website is a non-promotional global resource intended for healthcare professionals and decision makers who have a role in policy development.</p>
          </div>
          <div class="col-12 d-none d-lg-inline-block col-md-5 text-center">
            <img src="/img/ckd-hero-logo-device.png" alt="large image of yellow and white circles forming a larger circle for the CKD logo" />
          </div>
        </div>
        <p class="text-light pt-3 mb-3 text-center">The Inside CKD programme was initiated and is funded by AstraZeneca. Inside CKD is designed to project the prevalence and burden of chronic kidney disease (CKD). The programme incorporates the use of microsimulation modelling that was conducted by HealthLumen Ltd. Oversight of the programme was provided by a Scientific Steering Committee comprising 32 members.</p>
      </div>
    </div>
  </header>
</template>

<script>
import Navbar from "@/components/Navbar.vue";

export default {
  name: "HomepageHeader",
	components: { Navbar }
};
</script>
