<template>
  <HomepageHeader />
  <div class="home">
    <div class="container-xxl">
      <h1 class="text-center display-4">Inside CKD uses microsimulation modelling to project the clinical and economic burden of CKD <br> across 31 regions<sup>1</sup></h1>
      <!-- homepage 3 block section -->
      <div class="container-xxl mb-5 pb-4">
        <div class="row justify-content-center">
          <div class="col-12 col-md-6 col-xl-4 pb-4 mb-5">
            <div class="homepage-icons">
              <img src="img/projection-icon.png" alt="project graph icon on upward curve" class="img-responsive" />
              <div class="homepage-icon-text">
                <h3 class="text-center homepage-icon-header">Projection</h3>
                <p class="text-center ps-3 pe-3">To project the global clinical and economic burden of CKD from 2022 to 2027</p>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-4 pb-4 mb-5">
            <div class="homepage-icons">
              <img src="img/awareness-icon.png" alt="awareness icon showing a megaphone" class="img-responsive" />
              <div class="homepage-icon-text">
                <h3 class="text-center homepage-icon-header">Awareness</h3>
                <p class="text-center ps-3 pe-3">To increase the awareness of the clinical and economic burden of CKD<sup>2,3</sup></p>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-4 pb-4 mb-5">
            <div class="homepage-icons">
              <img src="img/solutions-icon.png" alt="solutions icon showing a light bulb" class="img-responsive" />
              <div class="homepage-icon-text">
                <h3 class="text-center homepage-icon-header">Solutions</h3>
                <p class="text-center ps-3 pe-3">To understand problems and to inform evidence-based shaping of CKD policy and guidelines<sup>1</sup></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid homepage-background">
      <div class="container-xxl mb-4">
        <div class="row mt-4 align-items-center">
          <div class="col-12 order-last order-lg-first text-center col-lg-6 mt-4 pt-2 pb-4">
            <img src="img/homepage-inside-ckd-map.png" class="img-responsive" alt="blue coloured image of a world map" />
          </div>
          <div class="col-12 col-lg-6 mt-4 mb-3 pt-4 pb-2 text-center text-lg-start">
            <h3>Inside CKD: explore the data</h3>
            <p class="pt-3 text-center text-md-start">Microsimulations can complement data from clinical trials by evaluating scenarios that cannot be feasibly researched in a real-world setting due to time, budget or ethical constraints. Policymakers can use microsimulation modelling to conduct population-level analyses, estimate epidemiological trends and project long-term implications for healthcare for a variety of diseases.<sup>1</sup></p>
            <p class="text-center text-md-start">Statistical microsimulations are dependent on the quality of the input data and based on a number of assumptions.  This model has been methodologically validated, however the output estimates are subject to limitations. Some key assumptions and limitations have been outlined below, please refer to the published manuscripts for full limitations and use of proxy data inputs.<sup>1–4</sup></p>
            <p class="text-center text-md-start">Key assumptions and limitations of Inside CKD</p>
              <ul class="text-start">
            <li>Assumes no major changes in the management of CKD between 2022 and 2027</li>
            <li>Assumes no novel therapeutics introduced or changes in the costs or availability of medicines between 2022 and 2027</li>
            <li>Limited publications and/or lack of national registry data necessitated proxy data use for some countries/regions</li>
            <li>Projections are unable to account for the influence of cultural, societal and economic performance on healthcare systems, which limits the ability to make balanced international comparisons of the data</li>
          </ul>
            <div class="pt-4 pb-3">
            <router-link to="/explore-the-data" class="hp-buttons">Explore now</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- homepage inside CKD model block -->
    <div class="container-xxl">
      <div class="row">
        <div class="col-12">
          <h1 class="text-center display-4 mt-3 p-2">The Inside CKD model engine</h1>
          <p class="text-center">Inside CKD uses a population-based approach for a given country or region. The population comprises 20 million virtual individuals who cycle annually through the microsimulation and who age each year from baseline to the final year. Microsimulations can be adjusted for different time frames, but the initial analyses were from 2022 to 2027. The data inputs (summarized in the figure below) were based on national data from a pragmatic literature review (or when necessary using proxy data when national data was not available)* and they define the likelihood of a change in health state for any given individual over time.<sup>1–4</sup></p>
        </div>
        <div class="d-none d-md-block col-12 m-1">
          <img src="/img/ckd-model-engine.svg" class="img-responsive" alt="CKD model engine infographic" />
        </div>
        <div class="d-md-none col-12">
          <img src="/img/ckd-model-engine-mobile.svg" class="img-responsive mx-auto d-block" alt="CKD model engine infographic" />
        </div>
        <div class="d-none d-md-block col-12 m-1">
          <h5 class="pt-3 pb-0">IMPORTANT NOTE</h5>
          <p class="model-engine-footnote">Country-specific data were used (when available) to develop a virtual, general population for each country.<sup>1,2</sup> Validity assessments and sensitivity analyses were conducted to assess the impact of input parameters and to validate outputs, and ensure that data are available at patient level, representative, accurate and complete.<sup>1,2</sup></p>
        </div>
      </div>
    </div>
    <!-- Citations -->
    <div class="container-xxl">
      <div class="row ps-2 pe-2">
        <div class="col-12 mb-3 p-2 text-center text-md-start">
          <p class="footnote">*Given the scope of Inside CKD and the breadth of input data needed, not all countries and/or regions had all the necessary data for every input. In these cases, proxy data were used from an algorithm-generated list of comparable regions (i.e. those with similar demographic, epidemiological and healthcare system variables/inputs) and were selected on the advice of an expert Steering Committee.</p>
          <p class="footnote"><strong>Abbreviations:</strong> CKD, chronic kidney disease; CV, cardiovascular; eGFR, estimated glomerular filtration rate; HF, heart failure; HTN, hypertension; KRT, kidney replacement therapy; MI, myocardial infarction; T2D, type 2 diabetes.</p>
          <p class="footnote"><strong>Citations:</strong><sup>1</sup>Tangri N, Chadban S, Cabrera C, Retat L, Sánchez JJG. Projecting the epidemiological and economic impact of chronic kidney disease using patient-level microsimulation modelling: rationale and methods of Inside CKD. <i>Adv Ther</i> 2023;40:265–81. doi:10.1007/s12325-022-02353-5; <sup>2</sup>Jha V, Al-Ghamdi SMG, Li G, Wu MS, Stafylas P, Retat L, <i>et al</i>. Global economic burden associated with chronic kidney disease: a pragmatic review of medical costs for the Inside CKD research programme. <i>Adv Ther</i> 2023;40:4405–20. doi: 10.1007/s12325-023-02608-9; <sup>3</sup>Chertow G, Correa-Rotter R, Eckardt K et al. Projecting the clinical burden of chronic kidney disease at the patient level (Inside CKD): a microsimulation modelling study. <i>eClinicalMedicine</i> 2024;102614. doi: 10.1016/j.eclinm.2024.102614; <sup>4</sup>Chadban S, Arıcı M, Power A et al. Projecting the economic burden of chronic kidney disease at the patient level (Inside CKD): a microsimulation modelling study. <i>eClinicalMedicine</i> 2024;102615. doi: 10.1016/j.eclinm.2024.102615.</p>
        </div>
      </div>
    </div>
    <!-- homepage steering committee block -->
    <div class="container-fluid grey-block-bg mt-4 mb-4">
      <div class="container-xxl">
        <div class="row p-4 justify-content-center">
          <h1 class="text-center display-4 pb-2">Meet the Scientific Steering Committee</h1>
          <div class="col-12 col-sm-6 col-md-3 pb-4">
            <div class="homepage-committee">
              <img src="img/steven-chadban.png" alt="headshot picture of committee member Steven Chadban" class="img-responsive" />
              <div class="committee-text-block">
                <h4 class="text-center">Steven Chadban</h4>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-3 pb-4">
            <div class="homepage-committee">
              <img src="img/albert-power.png" alt="headshot picture of committee member of Albert Power" class="img-responsive" />
              <div class="committee-text-block">
                <h4 class="text-center">Albert Power</h4>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-3 pb-4">
            <div class="homepage-committee">
              <img src="img/navdeep-tangri.png" alt="headshot picture of committee member Navdeep Tangri" class="img-responsive" />
              <div class="committee-text-block">
                <h4 class="text-center">Navdeep Tangri</h4>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-3 pb-4">
            <div class="homepage-committee">
              <img src="img/marcelo-batista.png" alt="headshot picture of committee member Marcelo Batista" class="img-responsive" />
              <div class="committee-text-block">
                <h4 class="text-center">Marcelo Batista</h4>
              </div>
            </div>
          </div>
        </div>
        <div class="view-committee text-center pt-4">
          <router-link to="/steering-committee" class="hp-buttons">View full Steering Committee</router-link>
        </div>
      </div>
    </div>
    <!-- homepage publications block -->
    <div class="container-xxl mt-4">
      <div class="row justify-content-center">
        <h1 class="text-center display-4">Publications</h1>
        <!-- publication 1 -->
        <div class="mb-5 pb-5">
          <div class="homepage-icons">
            <a href="https://doi.org/10.1007/s12325-022-02353-5" id="doi_023535" target="_blank" class="col-12 col-xl-6 mt-xl-0 publication_link_">
              <img src="img/publications-icon.png" alt="solutions icon showing a light bulb" class="img-responsive" />
              <div class="homepage-icon-text">
                <h3 class="text-center homepage-icon-header publication-title ps-3 pe-3">Projecting the Epidemiological and Economic Impact of Chronic Kidney Disease Using Patient-Level Microsimulation Modelling: Rationale and Methods of Inside CKD</h3>
                <p class="text-center ps-3 pe-3 publication-text">Click here to explore the <span class="bold-inline">rationale and methods</span> of the Inside CKD microsimulation</p>
              </div>
            </a>
          </div>
        </div>
      </div>
      <!-- publication 4 -->
      <div class="row">
        <div class="mb-5 pb-5">
          <div class="homepage-icons">
            <a href="https://doi.org/10.1007/s12325-023-02608-9" id="doi_026089" target="_blank" class="col-12 col-xl-6 mt-xl-0 publication_link_">
              <img src="img/publications-icon.png" alt="solutions icon showing a light bulb" class="img-responsive" />
              <div class="homepage-icon-text">
                <h3 class="text-center homepage-icon-header publication-title ps-3 pe-3">Global Economic Burden Associated with Chronic Kidney Disease: A Pragmatic Review of Medical Costs for the Inside CKD Research Programme</h3>
                <p class="text-center ps-3 pe-3 publication-text">Click here to explore a <span class="bold-inline">pragmatic review of costing inputs</span> that informed the economics module of the Inside CKD microsimulation and to explore the <span class="bold-inline">per-patient cost projections of CKD</span> management and disease-associated complications.</p>
              </div>
            </a>
          </div>
        </div>
      </div>
      <!-- More publications button -->
      <div class="publications text-center mt-5 mb-5 pt-4 pb-4">
        <router-link to="/publications" class="hp-buttons">More Publications</router-link>
      </div>
    </div>
  </div>
  <TopSearchRegions />
</template>

<script>
import TopSearchRegions from "@/components/TopSearchRegions.vue";
import HomepageHeader from "@/components/HomepageHeader.vue";
export default {
  name: "Home",
  components: {
    HomepageHeader,
    TopSearchRegions
  }
};
</script>
