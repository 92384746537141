<template>
  <header>
    <div class="explore-navbar-background">
      <!-- *** navbar *** -->
      <nav class="navbar navbar-top-strip navbar-dark navbar-expand-md">
        <div class="container-xxl">
          <a href="/" class="navbar-brand py-3">
            <img src="/img/ckd-logo.svg" alt="CKD White Logo" width="60" height="90" />
          </a>
          <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
            <img src="/img/hamburger-menu-icon.png" alt="yellow hamburger menu icon" />
          </button>
          <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
            <div class="offcanvas-header">
              <h3 class="offcanvas-title ps-3" id="offcanvasNavbarLabel">Menu</h3>
              <div class="p-3 off-canvas-close-button" data-bs-dismiss="offcanvas" aria-label="Close">
                <img src="/img/off-canvas-close-button.png" alt="Yellow cross close button" />
              </div>
            </div>
            <div class="ps-4">
              <ul class="navbar-nav justify-content-end align-center">
                <li class="nav-item nav-link px-2">
                  <router-link to="/">Welcome</router-link>
                </li>
                <li class="nav-item nav-link px-2">
                  <router-link to="/explore-the-data">Explore the data</router-link>
                </li>
                <li class="nav-item px-2 nav-link">
                  <router-link to="/publications">Publications</router-link>
                </li>
                <li class="nav-item px-2 nav-link">
                  <router-link to="/steering-committee">Steering Committee</router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: "ExploreTheDataNavbar"
};
</script>
