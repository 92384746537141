<template>
  <h2 class="p-3">Prevalence of diagnosed and undiagnosed CKD</h2>
  <div class="legenddiv" ref="legenddiv"></div>
  <div class="chartdiv" ref="chartdiv"></div>
  <div class="p-3"><p><small>Tip: hover the cursor over the graph to show data labels. Click the graph legend to show/hide diagnosed or undiagnosed CKD.</small></p></div>
</template>

<script>
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
export default {
  name: "PrevalenceOfDiagnosedAndUndiagnosedCkd",
  props: {
    country: String
  },
  async mounted() {
    am5.addLicense("AM5C317022592");

    let root = am5.Root.new(this.$refs.chartdiv);
    var legendRoot = am5.Root.new(this.$refs.legenddiv);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        layout: root.verticalLayout
      })
    );
// Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)]);

    let response = await fetch(
      `/data/prevalence-of-diagnosed-and-undiagnosed-ckd/${this.country}.json`,
      {
        method: "GET",
        headers: {
          Accept: "application/json"
        },
      }
    );

    const json = await response.json();

    let data = [];

    json.forEach((element) => {
      data.push({
        year: `${element.year}`,
        undiagnosed: element.undiagnosedPer100_000,
        diagnosed: element.diagnosedPer100_000
      });
    });

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    var xRenderer = am5xy.AxisRendererX.new(root, {
      strokeOpacity: 1,
      strokeWidth: 1
    });
    var xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "year",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {})
      })
    );

    xRenderer.grid.template.setAll({
      location: 1
    });

    xAxis.data.setAll(data);

    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 1,
          strokeWidth: 1
        }),
      })
    );

    // chart ticks

    var yRenderer = yAxis.get("renderer");
    yRenderer.ticks.template.setAll({
      stroke: am5.color(0x000000),
      visible: true
    });

    // remove comma from numbers on y-axis

    root.numberFormatter.setAll({
      numberFormat: "#",
      numericFields: ["valueY"]
    });

    yAxis.get("renderer").grid.template.set("forceHidden", true);
    xAxis.get("renderer").grid.template.set("forceHidden", true);

    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    var legend = legendRoot.container.children.unshift(
      am5.Legend.new(legendRoot, {
        width: am5.percent(100),
        centerX: am5.percent(50),
        x: am5.percent(60),
        paddingBottom: 20,
        layout: am5.GridLayout.new(root, {
          maxColumns: 3,
          fixedWidthGrid: true
        }),
      })
    );

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    function makeSeries(name, fieldName, colour) {
      var series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          stacked: true,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName,
          categoryXField: "year",
          fill: am5.color(colour),
          stroke: am5.color(colour)
        })
      );

      series.columns.template.setAll({
        tooltipText: "{name}: {valueY}",
        tooltipY: am5.percent(10)
      });

      series.data.setAll(data);

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      series.appear();

      legend.data.push(series);
    }

    makeSeries("Diagnosed", "diagnosed", "#F0AB00");
    makeSeries("Undiagnosed", "undiagnosed", "#003865");

    yAxis.children.unshift(
      am5.Label.new(root, {
        rotation: 270,
        text: "Number of cases (per 100 000)",
        fontWeight: "500",
        fontSize: 14,
        x: am5.p50,
        centerX: am5.p50,
        y: am5.p50,
        centerY: am5.p50,
        paddingBottom: 25,
        paddingRight: 20
      })
    );

    // Add export menu
    let exporting = am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      filePrefix: `prevalence-of-diagnosed-and-undiagnosed-ckd-${this.country}`
    });

    this.root = root;
  },

  beforeUnmount() {
    if (this.root) {
      this.root.dispose();
    }
  },
};
</script>

<style scoped>
.chartdiv {
  width: 100%;
  height: 500px;
}
.legenddiv {
  width: 100%;
  height: 65px;
}
</style>
