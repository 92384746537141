<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-8 ps-0 pe-0 ps-sm-2 pe-sm-2 rounded mt-3 mb-3">
        <!-- Chart 1 -->
        <OverallCosts v-if="chart === 'healthcare-costs-associated-with-ckd'" :country="country" />
        <!-- Chart 2 -->
        <CkdCostsByStage v-if="chart === 'healthcare-costs-associated-with-ckd'" :country="country" />
      </div>
      <div class="col-12 col-lg-4 accompanying-text">
        <div class="bg-light rounded mb-5 p-3 pt-2">
          <div v-html="accompanyingText"></div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <p>Note: data provided here are adapted from Chadban S, Arıcı M, Power A <i>et al</i>., 2024. Individual values have been rounded to two decimal places. Slight discrepancies may occur between the total sum reported as a result.
        If you are comparing data between countries, any variation observed may be attributable not only to distinct demographics and healthcare infrastructures, but also to disparities in input data, such as collection methodology, age of data, or definition criteria employed, therefore comparisons between countries should be interpreted carefully.
      </p>
    </div>
  </div>
</template>

<script>
import OverallCosts from "@/components/charts/OverallCosts";
import CkdCostsByStage from "@/components/charts/CkdCostsByStage";
import countries from '../../public/data/countries.json';

export default {
  name: "CountryDataTwoCharts",
  components: {
    CkdCostsByStage,
    OverallCosts
  },
  props: {
    country: String,
    chart: String
  },
  data() {
    return {
      countries: countries,
      accompanyingText: null
    }
  },
  mounted() {
    this.getAccompanyingText();
  },
  methods: {
    getAccompanyingText() {
      let country = this.country;

      this.countries.forEach((c) => {
        if (c.slug === country && c.accompanyingText[this.$route.params.chart]) {
          this.accompanyingText = c.accompanyingText[this.$route.params.chart];
        }
      });
    }
  }
};
</script>
