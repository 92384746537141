<template>
  <section>
    <div v-if="chart === 'population-demographics' || chart === 'prevalence-of-diagnosed-and-undiagnosed-ckd' || chart === 'prevalence-of-ckd-by-stage' || chart === 'prevalence-of-krt' || chart === 'ckd-as-a-proportion-of-healthcare-expenditure' || chart === 'burden-of-cv-complications-in-the-diagnosed-ckd-population' || chart === 'deaths-in-the-ckd-population'">
      <CountryDataOneChart :country="country" :chart="chart" />
    </div>
    <div v-if="chart === 'healthcare-costs-associated-with-ckd'">
      <CountryDataTwoCharts :country="country" :chart="chart" />
    </div>
    <div class="container-fluid bg-light">
      <div class="container-xxl">
        <div class="row justify-content-between align-items-center py-4 mb-5">
          <div class="col-12 col-md-6">
            <h2 class="display-6 text-left fst-italic">Compare these data with...</h2>
          </div>
          <div class="col-12 col-md-6 d-flex text-end">
            <div class="align-items-center justify-content-end">
              <p class="fst-italic pt-1 explore-label pe-2">Country/Region</p>
            </div>
            <select id="compareCountryDropdown" @change="changeCompareCountry()" v-model="selectedCompareCountry" class="form-select explore-dropdown-grey border-0 text-start" aria-label="1">
              <option disabled value="">Select a country</option>
              <option v-for="compareCountry in compareCountries" :value="compareCountry.slug" v-show="compareCountry.slug !== country" :key="compareCountry">{{ compareCountry.name }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showComparison">
      <div class="row mx-1 mb-2">
        <div class="col-12 col-md-6">
          <h2 class="text-left compare-country-heading">{{ getCountryFromSlug() }}</h2>
        </div>
      </div>
      <div v-if="chart === 'population-demographics' || chart === 'prevalence-of-diagnosed-and-undiagnosed-ckd' || chart === 'prevalence-of-ckd-by-stage' || chart === 'prevalence-of-krt' || chart === 'ckd-as-a-proportion-of-healthcare-expenditure' || chart === 'burden-of-cv-complications-in-the-diagnosed-ckd-population' || chart === 'deaths-in-the-ckd-population'">
        <CountryDataOneChart :country="selectedCompareCountry" :chart="chart" />
      </div>
      <div v-if="chart === 'healthcare-costs-associated-with-ckd'">
        <CountryDataTwoCharts :country="selectedCompareCountry" :chart="chart" />
      </div>
      <div class="container-xxl">
        <div class="row mb-3">
          <div class="col-12 text-center pt-4 pb-2">
            <h5>Click on next/previous to view the data for other countries/regions</h5>
          </div>
            <div class="col-12 justify-content-center text-center mb-4 d-flex">
              <button type="button" @click="previousCountry()" class="data-button data-button-previous m-2 text-center"><span><img src="/img/chevron-left.png" class="data-chevron float-start"></span>Previous</button>
              <button type="button" @click="nextCountry()" class="data-button data-button-next m-2">Next<span><img src="/img/white-chevron-right.png" class="data-chevron float-end"></span></button>
            </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CountryDataOneChart from "@/components/CountryDataOneChart.vue";
import CountryDataTwoCharts from "@/components/CountryDataTwoCharts.vue";
import CountryData from "@/components/ChartList.vue";
import countries from '../../public/data/countries.json';

export default {
  name: "ExploreTheDataChart",
  components: {
    CountryDataOneChart,
    CountryDataTwoCharts,
    CountryData,
  },
  data() {
    return {
      country: null,
      chart: null,
      compareCountries: countries,
      showComparison: false,
      selectedCompareCountry: ""
    }
  },
  mounted() {
    this.country = this.$route.params.country;
    this.chart = this.$route.params.chart;
  },
  methods: {
    getCountryFromSlug() {
      let countryName = '';
      countries.forEach((country) => {
        if (country.slug === this.selectedCompareCountry) {
          countryName = country.name;
        }
      });
      return countryName;
    },
    changeCompareCountry() {
      // Remove my-component from the DOM
      this.showComparison = false;

      this.$nextTick(() => {
        // Add the component back in
        this.showComparison = true;
      });
    },
    previousCountry() {
      for (let i = 0; i < countries.length; i++) {
        if (countries[i].slug === this.selectedCompareCountry) {
          if (i - 1 >= 0) {
            this.selectedCompareCountry = countries[i - 1].slug;
            break;
          }
          this.selectedCompareCountry = countries[countries.length - 1].slug;
          break;
        }
      }

      if (this.selectedCompareCountry === this.country){
        this.previousCountry();
      }

      this.changeCompareCountry();
    },
    nextCountry() {
      for (let i = 0; i < countries.length; i++) {
        if (countries[i].slug === this.selectedCompareCountry) {
          if (i + 1 < countries.length) {
            this.selectedCompareCountry = countries[i + 1].slug;
            break;
          }
          this.selectedCompareCountry = countries[0].slug;
          break;
        }
      }

      if (this.selectedCompareCountry === this.country){
        this.nextCountry();
      }

      this.changeCompareCountry();
    }
  }
};
</script>

