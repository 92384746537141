<template>
  <InternalPageHeader title="Meet the Scientific Steering" title-last-word="Committee"/>
  <div class="container-xxl">
    <div class="row justify-content-center mt-4">
      <div class="col-12 col-sm-8 text-center px-2">
        <p>The Inside CKD Steering Committee comprises 32 members across the globe.</p>
        <p>The Steering Committee shared their expertise for the design, implementation and interpretation of Inside CKD. They had oversight of clinical assumptions, gaps in available country-specific data, and interpretation of results from both a global and a country-specific perspective.</p>
      </div>
    </div>
  </div>
  <!-- committee members -->
  <div class="container-xxl mb-5">
    <div class="row justify-content-center mb-5">
      <!-- Australia -->
      <div class="col-12 col-sm-6 col-lg-4 committee-block-margins">
        <div class="homepage-committee">
          <img src="img/steven-chadban.png" alt="headshot picture of committee member Steven Chadban" class="img-responsive" />
          <div class="new-committee-text-block">
            <h4 class="text-center new-committee-name">Prof. Steven Chadban</h4>
            <p class="committee-country-text text-center mb-0 pb-2">Australia</p>
            <div class="steering-committee-flags d-flex justify-content-center">
              <img src="img/australia-flag.svg" alt="Image of the flag of Australia" class="new-country-flag pt-2 pb-2" />
            </div>
          </div>
        </div>
      </div>
      <!-- Belgium -->
      <div class="col-12 col-sm-6 col-lg-4 committee-block-margins">
        <div class="homepage-committee">
          <img src="img/michel-jadoul.png" alt="profile picture of Prof. Michel Jadoul" class="img-responsive" />
          <div class="new-committee-text-block">
            <h4 class="text-center new-committee-name">Prof. Michel Jadoul</h4>
            <p class="committee-country-text text-center mb-0 pb-2">Belgium</p>
            <div class="steering-committee-flags d-flex justify-content-center">
              <img src="img/belgium-flag.svg" alt="Image of the flag of Belgium" class="new-country-flag pt-2 pb-2" />
            </div>
          </div>
        </div>
      </div>
      <!-- Brazil -->
      <div class="col-12 col-sm-6 col-lg-4 committee-block-margins">
        <div class="homepage-committee">
          <img src="img/marcelo-batista.png" alt="profile picture of Prof. Marcelo Batista" class="img-responsive" />
          <div class="new-committee-text-block">
            <h4 class="text-center new-committee-name">Prof. Marcelo Batista</h4>
            <p class="committee-country-text text-center mb-0 pb-2">Brazil</p>
            <div class="steering-committee-flags d-flex justify-content-center">
              <img src="img/brazil-flag.svg" alt="Image of the flag of Brazil" class="new-country-flag pt-2 pb-2" />
            </div>
          </div>
        </div>
      </div>
      <!-- Canada -->
      <div class="col-12 col-sm-6 col-lg-4 committee-block-margins">
        <div class="homepage-committee">
          <img src="img/navdeep-tangri.png" alt="profile picture of Prof. Navdeep Tangri" class="img-responsive" />
          <div class="new-committee-text-block">
            <h4 class="text-center new-committee-name">Prof. Navdeep Tangri</h4>
            <p class="committee-country-text text-center mb-0 pb-2">Canada</p>
            <div class="steering-committee-flags d-flex justify-content-center">
              <img src="img/canada-flag.svg" alt="Image of the flag of Canada" class="new-country-flag pt-2 pb-2" />
            </div>
          </div>
        </div>
      </div>
      <!-- China -->
      <div class="col-12 col-sm-6 col-lg-4 committee-block-margins">
        <div class="homepage-committee">
          <img src="img/guisen-li.png" alt="profile picture of Prof. Guisen Li" class="img-responsive" />
          <div class="new-committee-text-block">
            <h4 class="text-center new-committee-name">Prof. Guisen Li</h4>
            <p class="committee-country-text text-center mb-0 pb-2">China</p>
            <div class="steering-committee-flags d-flex justify-content-center">
              <img src="img/china-flag.svg" alt="Image of the flag of China" class="new-country-flag pt-2 pb-2" />
            </div>
          </div>
        </div>
      </div>
      <!-- Colombia -->
      <div class="col-12 col-sm-6 col-lg-4 committee-block-margins">
        <div class="homepage-committee">
          <img src="img/javier-arango-alvarez.png" alt="profile picture of Prof. Javier Arango Álvarez" class="img-responsive" />
          <div class="new-committee-text-block">
            <h4 class="text-center new-committee-name">Prof. Javier Arango Álvarez</h4>
            <p class="committee-country-text text-center mb-0 pb-2">Colombia</p>
            <div class="steering-committee-flags d-flex justify-content-center">
              <img src="img/colombia-flag.svg" alt="Image of the flag of Colombia" class="new-country-flag pt-2 pb-2" />
            </div>
          </div>
        </div>
      </div>
      <!-- Denmark -->
      <div class="col-12 col-sm-6 col-lg-4 committee-block-margins">
        <div class="homepage-committee">
          <img src="img/christian-fynbo-christiansen.png" alt="profile picture of Prof. Christian Fynbo Christiansen" class="img-responsive" />
          <div class="new-committee-text-block">
            <h4 class="text-center new-committee-name">Prof. Christian F. Christiansen</h4>
            <p class="committee-country-text text-center mb-0 pb-2">Denmark</p>
            <div class="steering-committee-flags d-flex justify-content-center">
              <img src="img/denmark-flag.svg" alt="Image of the flag of Denmark" class="new-country-flag pt-2 pb-2" />
            </div>
          </div>
        </div>
      </div>
      <!-- France -->
      <div class="col-12 col-sm-6 col-lg-4 committee-block-margins">
        <div class="homepage-committee">
          <img src="img/j-m-halimi.png" alt="profile picture of Prof. Jean-Michel Halimi" class="img-responsive" />
          <div class="new-committee-text-block">
            <h4 class="text-center new-committee-name">Prof. Jean-Michel Halimi</h4>
            <p class="committee-country-text text-center mb-0 pb-2">France</p>
            <div class="steering-committee-flags d-flex justify-content-center">
              <img src="img/france-flag.svg" alt="Image of the flag of France" class="new-country-flag pt-2 pb-2" />
            </div>
          </div>
        </div>
      </div>
      <!-- Germany -->
      <div class="col-12 col-sm-6 col-lg-4 committee-block-margins">
        <div class="homepage-committee">
          <img src="img/kai-uwe-eckardt.png" alt="profile picture of Prof. Kai-Uwe Eckardt" class="img-responsive" />
          <div class="new-committee-text-block">
            <h4 class="text-center new-committee-name">Prof. Kai-Uwe Eckardt</h4>
            <p class="committee-country-text text-center mb-0 pb-2">Germany</p>
            <div class="steering-committee-flags d-flex justify-content-center">
              <img src="img/germany-flag.svg" alt="Image of the flag of Germany" class="new-country-flag pt-2 pb-2" />
            </div>
          </div>
        </div>
      </div>
      <!-- Greece -->
      <div class="col-12 col-sm-6 col-lg-4 committee-block-margins">
        <div class="homepage-committee">
          <img src="img/panos-stafylas.png" alt="profile picture of Prof. Panos Stafylas" class="img-responsive" />
          <div class="new-committee-text-block">
            <h4 class="text-center new-committee-name">Dr. Panos Stafylas</h4>
            <p class="committee-country-text text-center mb-0 pb-2">Greece</p>
            <div class="steering-committee-flags d-flex justify-content-center">
              <img src="img/greece-flag.svg" alt="Image of the flag of Greece" class="new-country-flag pt-2 pb-2" />
            </div>
          </div>
        </div>
      </div>
      <!-- Hungary -->
      <div class="col-12 col-sm-6 col-lg-4 committee-block-margins">
        <div class="homepage-committee">
          <img src="img/istvan-wittmann.png" alt="profile picture of Prof. István Wittmann" class="img-responsive" />
          <div class="new-committee-text-block">
            <h4 class="text-center new-committee-name">Prof. István Wittmann</h4>
            <p class="committee-country-text text-center mb-0 pb-2">Hungary</p>
            <div class="steering-committee-flags d-flex justify-content-center">
              <img src="img/hungary-flag.svg" alt="Image of the flag of Hungary" class="new-country-flag pt-2 pb-2" />
            </div>
          </div>
        </div>
      </div>
      <!-- India -->
      <div class="col-12 col-sm-6 col-lg-4 committee-block-margins">
        <div class="homepage-committee">
          <img src="img/vivekanand-jha.png" alt="profile picture of Prof. Vivekanand Jha" class="img-responsive" />
          <div class="new-committee-text-block">
            <h4 class="text-center new-committee-name">Prof. Vivekanand Jha</h4>
            <p class="committee-country-text text-center mb-0 pb-2">India</p>
            <div class="steering-committee-flags d-flex justify-content-center">
              <img src="img/india-flag.svg" alt="Image of the flag of India" class="new-country-flag pt-2 pb-2" />
            </div>
          </div>
        </div>
      </div>
      <!-- Israel -->
      <div class="col-12 col-sm-6 col-lg-4 committee-block-margins">
        <div class="homepage-committee">
          <img src="img/avraham-karasik.png" alt="profile picture of Prof. Avraham Karasik" class="img-responsive" />
          <div class="new-committee-text-block">
            <h4 class="text-center new-committee-name">Prof. Avraham Karasik</h4>
            <p class="committee-country-text text-center mb-0 pb-2">Israel</p>
            <div class="steering-committee-flags d-flex justify-content-center">
              <img src="img/israel-flag.svg" alt="Image of the flag of Israel" class="new-country-flag pt-2 pb-2" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-lg-4 committee-block-margins">
        <div class="homepage-committee">
          <img src="img/gil-chernin.png" alt="profile picture of Prof. Gil Chernin" class="img-responsive" />
          <div class="new-committee-text-block">
            <h4 class="text-center new-committee-name">Prof. Gil Chernin</h4>
            <p class="committee-country-text text-center mb-0 pb-2">Israel</p>
            <div class="steering-committee-flags d-flex justify-content-center">
              <img src="img/israel-flag.svg" alt="Image of the flag of Israel" class="new-country-flag pt-2 pb-2" />
            </div>
          </div>
        </div>
      </div>
      <!-- Italy -->
      <div class="col-12 col-sm-6 col-lg-4 committee-block-margins">
        <div class="homepage-committee">
          <img src="img/francesco-mennini.png" alt="profile picture of Prof. Francesco Saverio Mennini" class="img-responsive" />
          <div class="new-committee-text-block">
            <h4 class="text-center new-committee-name">Prof. Francesco S. Mennini</h4>
            <p class="committee-country-text text-center mb-0 pb-2">Italy</p>
            <div class="steering-committee-flags d-flex justify-content-center">
              <img src="img/italy-flag.svg" alt="Image of the flag of Italy" class="new-country-flag pt-2 pb-2" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-lg-4 committee-block-margins">
        <div class="homepage-committee">
          <img src="img/luca-nicola.png" alt="profile picture of Prof. Luca De Nicola" class="img-responsive" />
          <div class="new-committee-text-block">
            <h4 class="text-center new-committee-name">Prof. Luca De Nicola</h4>
            <p class="committee-country-text text-center mb-0 pb-2">Italy</p>
            <div class="steering-committee-flags d-flex justify-content-center">
              <img src="img/italy-flag.svg" alt="Image of the flag of Italy" class="new-country-flag pt-2 pb-2" />
            </div>
          </div>
        </div>
      </div>
      <!-- Japan -->
      <div class="col-12 col-sm-6 col-lg-4 committee-block-margins">
        <div class="homepage-committee">
          <img src="img/e-kanda.png" alt="profile picture of Prof. Eiichiro Kanda" class="img-responsive" />
          <div class="new-committee-text-block">
            <h4 class="text-center new-committee-name">Prof. Eiichiro Kanda</h4>
            <p class="committee-country-text text-center mb-0 pb-2">Japan</p>
            <div class="steering-committee-flags d-flex justify-content-center">
              <img src="img/japan-flag.svg" alt="Image of the flag of Japan" class="new-country-flag pt-2 pb-2" />
            </div>
          </div>
        </div>
      </div>
      <!-- Mexico -->
      <div class="col-12 col-sm-6 col-lg-4 committee-block-margins">
        <div class="homepage-committee">
          <img src="img/r-correa-rotter.png" alt="profile picture of Prof. José Ricardo Correa-Rotter" class="img-responsive" />
          <div class="new-committee-text-block">
            <h4 class="text-center new-committee-name">Prof. José Ricardo Correa-Rotter</h4>
            <p class="committee-country-text text-center mb-0 pb-2">Mexico</p>
            <div class="steering-committee-flags d-flex justify-content-center">
              <img src="img/mexico-flag.svg" alt="Image of the flag of Mexico" class="new-country-flag pt-2 pb-2" />
            </div>
          </div>
        </div>
      </div>
      <!-- Netherlands -->
      <div class="col-12 col-sm-6 col-lg-4 committee-block-margins">
        <div class="homepage-committee">
          <img src="img/chris-hagen.png" alt="profile picture of Dr Chris Hagen" class="img-responsive" />
          <div class="new-committee-text-block">
            <h4 class="text-center new-committee-name">Dr Chris Hagen</h4>
            <p class="committee-country-text text-center mb-0 pb-2">Netherlands</p>
            <div class="steering-committee-flags d-flex justify-content-center">
              <img src="img/netherlands-flag.svg" alt="Image of the flag of the Netherlands" class="new-country-flag pt-2 pb-2" />
            </div>
          </div>
        </div>
      </div>
      <!-- Poland -->
      <div class="col-12 col-sm-6 col-lg-4 committee-block-margins">
        <div class="homepage-committee">
          <img src="img/michal-nowicki.png" alt="profile picture of Prof. Michał Nowicki" class="img-responsive" />
          <div class="new-committee-text-block">
            <h4 class="text-center new-committee-name">Prof. Michał Nowicki</h4>
            <p class="committee-country-text text-center mb-0 pb-2">Poland</p>
            <div class="steering-committee-flags d-flex justify-content-center">
              <img src="img/poland-flag.svg" alt="Image of the flag of Poland" class="new-country-flag pt-2 pb-2" />
            </div>
          </div>
        </div>
      </div>
      <!-- Romania -->
      <div class="col-12 col-sm-6 col-lg-4 committee-block-margins">
        <div class="homepage-committee">
          <img src="img/ismail-gener.png" alt="profile picture of Prof. Ismail Gener" class="img-responsive" />
          <div class="new-committee-text-block">
            <h4 class="text-center new-committee-name">Prof. Ismail Gener</h4>
            <p class="committee-country-text text-center mb-0 pb-2">Romania</p>
            <div class="steering-committee-flags d-flex justify-content-center">
              <img src="img/romania-flag.svg" alt="Image of the flag of Romania" class="new-country-flag pt-2 pb-2" />
            </div>
          </div>
        </div>
      </div>
      <!-- Singapore -->
      <div class="col-12 col-sm-6 col-lg-4 committee-block-margins">
        <div class="homepage-committee">
          <img src="img/j-choo-chon-jun.png" alt="profile picture of Prof. Jason Choo Chon Jun" class="img-responsive" />
          <div class="new-committee-text-block">
            <h4 class="text-center new-committee-name">Assoc Prof. Jason Choo</h4>
            <p class="committee-country-text text-center mb-0 pb-2">Singapore</p>
            <div class="steering-committee-flags d-flex justify-content-center">
              <img src="img/singapore-flag.svg" alt="Image of the flag of Saudi Arabia" class="new-country-flag pt-2 pb-2 pe-2" />
              <img src="img/thailand-flag.svg" alt="Image of the flag of Thailand" class="new-country-flag pt-2 pb-2 pe-2" />
              <img src="img/philippines-flag.png" alt="Image of the flag of the Philippines" class="new-country-flag pt-2 pb-2" />
            </div>
          </div>
        </div>
      </div>
      <!-- South Korea -->
      <div class="col-12 col-sm-6 col-lg-4 committee-block-margins">
        <div class="homepage-committee">
          <img src="img/kook-hwan-oh.png" alt="profile picture of Prof. Kook-Hwan Oh" class="img-responsive" />
          <div class="new-committee-text-block">
            <h4 class="text-center new-committee-name">Prof. Kook-Hwan Oh</h4>
            <p class="committee-country-text text-center mb-0 pb-2">South Korea</p>
            <div class="steering-committee-flags d-flex justify-content-center">
              <img src="img/south-korea-flag.svg" alt="Image of the flag of South Korea" class="new-country-flag pt-2 pb-2" />
            </div>
          </div>
        </div>
      </div>
      <!-- Spain -->
      <div class="col-12 col-sm-6 col-lg-4 committee-block-margins">
        <div class="homepage-committee">
          <img src="img/jf-navarro-gonzalez.png" alt="profile picture of Prof. Juan F. Navarro-González" class="img-responsive" />
          <div class="new-committee-text-block">
            <h4 class="text-center new-committee-name">Prof. Juan F. Navarro-González</h4>
            <p class="committee-country-text text-center mb-0 pb-2">Spain</p>
            <div class="steering-committee-flags d-flex justify-content-center">
              <img src="img/spain-flag.svg" alt="Image of the flag of Spain" class="new-country-flag pt-2 pb-2" />
            </div>
          </div>
        </div>
      </div>
      <!-- Sweden-->
      <div class="col-12 col-sm-6 col-lg-4 committee-block-margins">
        <div class="homepage-committee">
          <img src="img/j-arnlov.png" alt="profile picture of Prof. Johan Ärnlöv" class="img-responsive" />
          <div class="new-committee-text-block">
            <h4 class="text-center new-committee-name">Prof. Johan Ärnlöv</h4>
            <p class="committee-country-text text-center mb-0 pb-2">Sweden</p>
            <div class="steering-committee-flags d-flex justify-content-center">
              <img src="img/sweden-flag.svg" alt="Image of the flag of Sweden" class="new-country-flag pt-2 pb-2" />
            </div>
          </div>
        </div>
      </div>
      <!-- Taiwan-->
      <div class="col-12 col-sm-6 col-lg-4 committee-block-margins">
        <div class="homepage-committee">
          <img src="img/mai-szu-wu.png" alt="profile picture of Prof. Mai-Szu Wu" class="img-responsive" />
          <div class="new-committee-text-block">
            <h4 class="text-center new-committee-name">Prof. Mai-Szu Wu</h4>
            <p class="committee-country-text text-center mb-0 pb-2">Taiwan</p>
            <div class="steering-committee-flags d-flex justify-content-center">
              <img src="img/taiwan-flag.svg" alt="Image of the flag of Taiwan" class="new-country-flag pt-2 pb-2" />
            </div>
          </div>
        </div>
      </div>
      <!-- Türkiye -->
      <div class="col-12 col-sm-6 col-lg-4 committee-block-margins">
        <div class="homepage-committee">
          <img src="img/mustafa-arici.png" alt="profile picture of Prof. Mustafa Arici" class="img-responsive" />
          <div class="new-committee-text-block">
            <h4 class="text-center new-committee-name">Prof. Mustafa Arici</h4>
            <p class="committee-country-text text-center mb-0 pb-2">Türkiye</p>
            <div class="steering-committee-flags d-flex justify-content-center">
              <img src="img/turkey-flag.png" alt="Image of the flag of Türkiye" class="new-country-flag pt-2 pb-2" />
            </div>
          </div>
        </div>
      </div>
      <!-- UAE -->
      <div class="col-12 col-sm-6 col-lg-4 committee-block-margins">
        <div class="homepage-committee">
          <img src="img/stephen-holt.png" alt="profile picture of Prof. Stephen Holt" class="img-responsive" />
          <div class="new-committee-text-block">
            <h4 class="text-center new-committee-name">Prof. Stephen Holt</h4>
            <p class="committee-country-text text-center mb-0 pb-2">UAE</p>
            <div class="steering-committee-flags d-flex justify-content-center">
              <img src="img/uae-flag.svg" alt="Image of the flag of UAE" class="new-country-flag pt-2 pb-2" />
            </div>
          </div>
        </div>
      </div>
      <!-- UK -->
      <div class="col-12 col-sm-6 col-lg-4 committee-block-margins">
        <div class="homepage-committee">
          <img src="img/albert-power.png" alt="profile picture of Prof. Albert Power" class="img-responsive" />
          <div class="new-committee-text-block">
            <h4 class="text-center new-committee-name">Prof. Albert Power</h4>
            <p class="committee-country-text text-center mb-0 pb-2">UK</p>
            <div class="steering-committee-flags d-flex justify-content-center">
              <img src="img/uk-flag.svg" alt="Image of the flag of the UK" class="new-country-flag pt-2 pb-2" />
            </div>
          </div>
        </div>
      </div>
      <!-- US -->
      <div class="col-12 col-sm-6 col-lg-4 committee-block-margins">
        <div class="homepage-committee">
          <img src="img/glenn-chertow.png" alt="profile picture of Prof. Glenn Chertow" class="img-responsive" />
          <div class="new-committee-text-block">
            <h4 class="text-center new-committee-name">Prof. Glenn Chertow</h4>
            <p class="committee-country-text text-center mb-0 pb-2">US</p>
            <div class="steering-committee-flags d-flex justify-content-center">
              <img src="img/us-flag.svg" alt="Image of the flag of the US" class="new-country-flag pt-2 pb-2" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-lg-4 committee-block-margins">
        <div class="homepage-committee">
          <img src="img/jay-wish.png" alt="profile picture of Prof. Jay Wish" class="img-responsive" />
          <div class="new-committee-text-block">
            <h4 class="text-center new-committee-name">Prof. Jay Wish</h4>
            <p class="committee-country-text text-center mb-0 pb-2">US</p>
            <div class="steering-committee-flags d-flex justify-content-center">
              <img src="img/us-flag.svg" alt="Image of the flag of the US" class="new-country-flag pt-2 pb-2" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-5">
    <div class="col"></div>
  </div>
  <TopSearchRegions />
</template>

<script>
import TopSearchRegions from "@/components/TopSearchRegions.vue";
import InternalPageHeader from "@/components/InternalPageHeader.vue";

export default {
  name: "SteeringCommittee.vue",
  components: {
	  InternalPageHeader,
	  TopSearchRegions
  }
};
</script>
