<template>
  <header>
    <div class="internal-nav-background">
	    <Navbar />
      <div class="container-xxl">
        <div class="row d-flex justify-content-between mt-3">
          <div class="col-12">
            <h1 class="text-center text-light display-5 pt-3 pb-5 mb-5">
              {{ title }}
              <span class="yellow-heading">{{ titleLastWord }}</span>
            </h1>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import Navbar from "@/components/Navbar.vue";

export default {
  name: "InternalPageHeader",
	props: {
	  title: String,
    titleLastWord: String
	},
	components: { Navbar }
};
</script>
