<template>
  <header>
    <div class="breadcrumb-internal-nav-background">
      <Navbar />
      <!-- breadcrumb & secondary mobile anchor nav  -->
      <div class="container-fluid breadcrumb-transparent-layer">
        <div class="container-xxl">
          <!-- breadcrumb nav -->
          <div class="row py-3">
            <div class="col-12 col-md-7 mt-3 py-2 ps-2">
              <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
                <ol class="breadcrumb text-light">
                  <li class="breadcrumb-item"><router-link to="/explore-the-data">World map</router-link></li>
                  <li class="breadcrumb-item"><a :href="'/explore-the-data/' + country.slug">{{ country.name }}</A></li>
                  <li v-if="chart" class="breadcrumb-item breadcrumb-end" aria-current="page">{{ getChartNameFromSlug(chart) }}</li>
                </ol>
              </nav>
            </div>
            <div class="col-12 col-md-5 d-flex justify-content-end" v-if="chart && (chart !== 'healthcare-costs-associated-with-ckd' && chart !== 'deaths-in-the-ckd-population')"></div>
          </div>
        </div>
      </div>
    </div>
  </header>

  <section>
    <div class="container-xxl">
      <div class="row mx-1">
        <div class="col-12">
          <h1 class="pt-5 fst-italic fw-bold">{{ country.name }}</h1>
        </div>
      </div>
      <div v-if="chart">
        <div class="row justify-content-end">
          <div class="col-12 col-sm-4 pb-5 pe-3">
            <h5 class="text-end small">Select a different chart:</h5>
            <select class="form-select" @change="changeChart()" v-model="chart" aria-label="Select a different chart">
              <option disabled value="">Select a different chart</option>
              <option v-for="chart in charts" :value="chart.slug">{{ chart.name }}</option>
            </select>
          </div>
        </div>
        <ExploreTheDataChart v-if="chart" />
      </div>
      <div v-else>
        <div class="row">
          <div class="col-12 mb-5">
            <div class="card px-3 explore-block text-light">
              <div class="card-body pt-4">
                <ChartList :slug="countrySlug" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ChartList from "@/components/ChartList";
import ExploreTheDataChart from "@/views/ExploreTheDataChart";
import Navbar from "@/components/Navbar";
import countries from '../../public/data/countries.json';

export default {
  name: "ExploreTheDataCountry",
  components: {
    ChartList,
    ExploreTheDataChart,
    Navbar
  },
  data() {
    return {
      chart: null,
      countries: countries,
      country: {},
      countrySlug: null,
      charts: [
        { slug: 'population-demographics', name: 'Population demographics'},
        { slug: 'prevalence-of-diagnosed-and-undiagnosed-ckd', name: 'Prevalence of diagnosed and undiagnosed CKD' },
        { slug: 'prevalence-of-ckd-by-stage', name: 'Prevalence of CKD by stage' },
        { slug: 'prevalence-of-krt', name: 'Prevalence of KRT' },
        { slug: 'healthcare-costs-associated-with-ckd', name: 'Healthcare costs associated with CKD' },
        { slug: 'ckd-as-a-proportion-of-healthcare-expenditure', name: 'CKD as a proportion of healthcare expenditure' },
        { slug: 'burden-of-cv-complications-in-the-diagnosed-ckd-population', name: 'Burden of CV complications in the diagnosed CKD population' },
        { slug: 'deaths-in-the-ckd-population', name: 'Deaths in the CKD population' }
      ]
    }
  },
  mounted() {
    this.chart = this.$route.params.chart;
    this.countrySlug = this.$route.params.country;
    this.getCountryFromSlug();
  },
  methods: {
    getCountryFromSlug() {
      this.countries.forEach((country) => {
        if (country.slug === this.countrySlug) {
          this.country = country;
        }
      });
    },
    getChartNameFromSlug(slug) {
      let result = this.charts.find(item => item.slug === slug);

      if (result) {
        return result.name;
      }
      return null;
    },
    changeChart() {
      window.location.href = `/explore-the-data/` + `${this.countrySlug}` + `/${this.chart}`;
    }
  }
};
</script>

