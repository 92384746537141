<template>
  <h2 class="p-3">Cumulative number of all-cause deaths in the CKD population between 2022 and 2027</h2>
  <div class="chartdiv" ref="chartdiv"></div>
  <div class="p-3"><p><small>Tip: hover the cursor over the graph to show data labels.</small></p></div>
</template>

<script>
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
export default {
  name: "DeathsInTheCkdPopulation",
  props: {
    country: String
  },
  mounted: async function() {
    am5.addLicense("AM5C317022592");

    let root = am5.Root.new(this.$refs.chartdiv);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        layout: root.verticalLayout
      })
    );

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)]);

    let response = await fetch(`/data/deaths-in-the-ckd-population/${this.country}.json`, {
      method: "GET",
      headers: {
        Accept: "application/json"
      }
    });

    const json = await response.json();

    let data = [];

    json.forEach((element) => {
      data.push({
        year: `${element.year}`,
        cumulativeMortalityCkdStage1: element.cumulativeMortalityCkdStage1,
        cumulativeMortalityCkdStage2: element.cumulativeMortalityCkdStage2,
        cumulativeMortalityCkdStage3A: element.cumulativeMortalityCkdStage3A,
        cumulativeMortalityCkdStage3B: element.cumulativeMortalityCkdStage3B,
        cumulativeMortalityCkdStage4: element.cumulativeMortalityCkdStage4,
        cumulativeMortalityCkdStage5: element.cumulativeMortalityCkdStage5
      });
    });

    // Create axes

    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    var xRenderer = am5xy.AxisRendererX.new(root, {
      cellStartLocation: 0.1,
      cellEndLocation: 0.9,
      strokeOpacity: 1,
      strokeWidth: 1
    });

    var xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        // categoryField: "xAxisLabel",
        renderer: xRenderer
      })
    );

    xRenderer.grid.template.setAll({
      location: 1
    });

    xAxis.data.setAll(data);

    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 1,
          strokeWidth: 1
        }),
        min: 0
      })
    );

    // chart ticks

    var yRenderer = yAxis.get("renderer");
    yRenderer.ticks.template.setAll({
      stroke: am5.color(0x000000),
      visible: true
    });

    // Axis labels

    // Dynamic label text and number format due to UAE data being in thousands instead of millions
    let yLabelText = "Cumulative number of deaths in the CKD population 2022–2027 (millions)";
    let numberFormat = "####.00";

    if (this.country === "uae") {
      yLabelText = "Cumulative number of deaths in the CKD population 2022–2027";
      numberFormat = "####";
    }

    yAxis.children.unshift(
      am5.Label.new(root, {
        text: yLabelText,
        fontSize: 14,
        rotation: 270,
        x: am5.p50,
        centerX: am5.p50,
        y: am5.p50,
        centerY: am5.p50,
        fontWeight: "600",
        paddingBottom: 25
      })
    );

    xAxis.children.unshift(
      am5.Label.new(root, {
        text: "2027",
        x: am5.p50,
        centerX: am5.p50,
        y: am5.p50,
        centerY: am5.p50,
        fontWeight: "700"
      })
    );

    root.numberFormatter.setAll({
      numberFormat: numberFormat,
      numericFields: ["valueY"]
    });

    yAxis.get("renderer").grid.template.set("forceHidden", true);
    xAxis.get("renderer").grid.template.set("forceHidden", true);

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    function makeSeries(name, fieldName, colour) {
      var series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          stacked: true,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName,
          categoryXField: "year",
          fill: am5.color(colour)
        })
      );

      series.bullets.push(function() {
        return am5.Bullet.new(root, {
          locationY: 0.5,
          locationX: 0.5,
          sprite: am5.Label.new(root, {
            fill: root.interfaceColors.get("alternativeText"),
            centerY: am5.percent(50),
            centerX: am5.percent(50),
            populateText: true
          })
        });
      });

      // Format number so it shows two decimal places even with a zero on the end
      // root.numberFormatter.setAll({
      //   numberFormat: "####.00",
      //   numericFields: ["valueY"]
      // });


      series.columns.template.setAll({
        tooltipText: "{valueY}",
        width: am5.percent(70),
        tooltipY: 0,
        strokeOpacity: 0
      });

      series.data.setAll(data);

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      series.appear();
    }

    makeSeries("cumulativeMortalityCkdStage1", "cumulativeMortalityCkdStage1", "#3F4444");
    makeSeries("cumulativeMortalityCkdStage2", "cumulativeMortalityCkdStage2", "#9DB0AC");
    makeSeries("cumulativeMortalityCkdStage3A", "cumulativeMortalityCkdStage3A", "#68D2DF");
    makeSeries("cumulativeMortalityCkdStage3B", "cumulativeMortalityCkdStage3B", "#00ADD0");
    makeSeries("cumulativeMortalityCkdStage4", "cumulativeMortalityCkdStage4", "#F0AB00");
    makeSeries("cumulativeMortalityCkdStage5", "cumulativeMortalityCkdStage5", "#003865");

    // Add legend

    var legend = chart.children.unshift(
      am5.Legend.new(root, {
        nameField: "name",
        fillField: "color",
        strokeField: "color",
        centerX: am5.percent(50),
        x: am5.percent(60),
        layout: am5.GridLayout.new(root, {
          maxColumns: 3,
          fixedWidthGrid: true
        }),
        paddingBottom: 65
      })
    );

    legend.data.setAll([
      {
        name: "CKD stage 1",
        color: am5.color("#3F4444")
      },
      {
        name: "CKD stage 2",
        color: am5.color("#9DB0AC")
      },
      {
        name: "CKD stage 3a",
        color: am5.color("#68D2DF")
      },
      {
        name: "CKD stage 3b",
        color: am5.color("#00ADD0")
      },
      {
        name: "CKD stage 4",
        color: am5.color("#F0AB00")
      },
      {
        name: "CKD stage 5 (including KRT)",
        color: am5.color("#003865")
      }

    ]);

    // Add export menu

    let exporting = am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      filePrefix: `deaths-in-the-ckd-population-${this.country}`
    });

    this.root = root;
  },
  methods: {
    toTimestamp(year) {
      var newDate = new Date(year);
      return newDate.getTime();
    },
  },
  beforeUnmount() {
    if (this.root) {
      this.root.dispose();
    }
  },
};
</script>

<style scoped>
.chartdiv {
  width: 100%;
  height: 750px;
}
</style>
