import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import PageNotFound from "@/views/PageNotFound.vue";
import PublicationsView from "@/views/Publications.vue";
import SteeringCommittee from "@/views/SteeringCommittee.vue";
import ExploreTheData from "@/views/ExploreTheData.vue";
import ExploreTheDataCountry from "@/views/ExploreTheDataCountry";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/publications",
    name: "Publications",
    component: PublicationsView
  },
  {
    path: "/steering-committee",
    name: "Steering committee",
    component: SteeringCommittee
  },
  {
    path: "/explore-the-data",
    name: "Explore the data",
    component: ExploreTheData
  },
  {
    path: "/explore-the-data/:country(australia|belgium|brazil|canada|china|colombia|denmark|france|germany|greece|hungary|india|israel|italy|japan|mexico|netherlands|philippines|poland|romania|saudi-arabia|singapore|south-korea|spain|sweden|taiwan|thailand|turkey|uae|uk|us)/:chart(population-demographics|prevalence-of-diagnosed-and-undiagnosed-ckd|prevalence-of-ckd-by-stage|prevalence-of-krt|healthcare-costs-associated-with-ckd|ckd-as-a-proportion-of-healthcare-expenditure|burden-of-cv-complications-in-the-diagnosed-ckd-population|deaths-in-the-ckd-population|)?",
    name: "Explore the data country",
    component: ExploreTheDataCountry
  },
  {
    path: "/:pathMatch(.*)*",
    name: "Page not found",
    component: PageNotFound
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active-link"
});

router.beforeEach((to, from, next) => {
  if (to.name === "Explore the data country" && to.params.country.length && to.params.chart.length) {
    document.title = 'Inside CKD - ' + getCountryFromSlug(to.params.country) + ' - ' + getChartNameFromSlug(to.params.chart);
  } else if (to.name === "Explore the data country" && to.params.country.length) {
    document.title = 'Inside CKD - ' + getCountryFromSlug(to.params.country);
  } else {
    document.title = `Inside CKD - ${to.name}`;
  }
  next();
});

export default router;

function getCountryFromSlug(slug) {
  if (slug === 'saudi-arabia') {
    return 'Saudi Arabia';
  } else if (slug === 'south-korea') {
    return 'South Korea';
  } else if (slug === 'uae' || slug === 'uk' || slug === 'us') {
    return slug.toUpperCase();
  } else {
    return slug.charAt(0).toUpperCase() + slug.slice(1);
  }
}
function getChartNameFromSlug(chart) {
  if (chart === 'population-demographics') {
    return 'Population demographics';
  } else if (chart === 'prevalence-of-diagnosed-and-undiagnosed-ckd') {
    return 'Prevalence of diagnosed and undiagnosed CKD';
  } else if (chart === 'prevalence-of-ckd-by-stage') {
    return 'Prevalence of CKD by stage';
  } else if (chart === 'prevalence-of-krt') {
    return 'Prevalence of KRT';
  } else if (chart === 'healthcare-costs-associated-with-ckd') {
    return 'Healthcare costs associated with CKD';
  } else if (chart === 'ckd-as-a-proportion-of-healthcare-expenditure') {
    return 'CKD as a proportion of healthcare expenditure';
  } else if (chart === 'burden-of-cv-complications-in-the-diagnosed-ckd-population') {
    return 'Burden of CV complications in the diagnosed CKD population';
  } else if (chart === 'deaths-in-the-ckd-population') {
    return 'Deaths in the CKD population';
  }
  return '';
}
