<template>
  <InternalPageHeader />
    <div class="container-xxl not-found">
      <div class="row m-3">
        <div class="col-12 justify-content-center text-center mt-1 mb-4">
          <h1 class="display-1">Page Not Found</h1>
          <p>Sorry but the page you are looking for does not exist.</p>
          <button class="text-dark data-button data-button-next"><router-link to="/">Back to Homepage</router-link> </button>
        </div>
      </div>
    </div>
</template>

<script>
import InternalPageHeader from "@/components/InternalPageHeader.vue";

export default {
    name: "PageNotFound",
    components: { InternalPageHeader }
};
</script>
