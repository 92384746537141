<template>
  <h2 class="p-3">Cumulative burden of CV complications in the diagnosed CKD population stratified by heart
    failure, myocardial infarction and stroke</h2>
  <div class="chartdiv" ref="chartdiv"></div>
  <div class="p-3"><p><small>Tip: hover the cursor over the graph to show data labels.</small></p></div>
</template>

<script>
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";

export default {
  name: "BurdenOfCvComplicationsInTheDiagnosedCkdPopulation",
  props: {
    country: String,
  },
  async mounted() {
    am5.addLicense("AM5C317022592");

    let root = am5.Root.new(this.$refs.chartdiv);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        layout: root.verticalLayout,
      })
    );

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)]);

    let response = await fetch(`/data/burden-of-cv-complications-in-the-diagnosed-ckd-population/${this.country}.json`, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    });

    const json = await response.json();

    let data = [];

    json.forEach((element) => {
      data.push({
        year: `${element.year}`,
        stroke: element.stroke,
        heartFailure: element.heartFailure,
        myocardialInfarction: element.myocardialInfarction,
      });
    });

    var xRenderer = am5xy.AxisRendererX.new(root, {
      cellStartLocation: 0.1,
      cellEndLocation: 0.9,
      strokeOpacity: 1,
      strokeWidth: 1
    });

    var xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "xAxisLabel",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {})
      })
    );

    xRenderer.grid.template.setAll({
      location: 1
    });

    xAxis.data.setAll(data);

    // make y-axis max at 17 for Taiwan only

    if(this.country === "taiwan") {
      var yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          min: 0,
          max: 0.17,
          strictMinMax: true,
          renderer: am5xy.AxisRendererY.new(root, {
            strokeOpacity: 1,
            strokeWidth: 1
          }),
        })
      );
    } else {
      var yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          min: 0,
          renderer: am5xy.AxisRendererY.new(root, {
            strokeOpacity: 1,
            strokeWidth: 1,
          }),
        })
      );
    }

    // chart ticks
    var yRenderer = yAxis.get("renderer");
    yRenderer.ticks.template.setAll({
      stroke: am5.color(0x000000),
      visible: true
    });

    yAxis.get("renderer").grid.template.set("forceHidden", true);
    xAxis.get("renderer").grid.template.set("forceHidden", true);

    // Axis labels

    // Dynamic label text and number format due to UAE data being in thousands instead of millions
    let yLabelText = "Cumulative incidence (millions)";
    let numberFormat = "####.00";

    if (this.country === "uae") {
      yLabelText = "Cumulative incidence";
      numberFormat = "####";
    } else if(this.country === "israel") {
      numberFormat = "##";
    }

    yAxis.children.unshift(
      am5.Label.new(root, {
        rotation: 270,
        text: yLabelText,
        x: am5.p50,
        centerX: am5.p50,
        y: am5.p50,
        centerY: am5.p50,
        fontWeight: "700",
        paddingBottom: 20
      })
    );

    xAxis.children.unshift(
      am5.Label.new(root, {
        text: "2027",
        x: am5.p50,
        centerX: am5.p50,
        y: am5.p50,
        centerY: am5.p50,
        fontWeight: "700"
      })
    );

    root.numberFormatter.setAll({
      numberFormat: numberFormat,
      numericFields: ["valueY"]
    });

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    function createSeries(field, name, colour) {

      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
      var series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          stacked: true,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: field,
          categoryXField: "year",
          fill: am5.color(colour),
          stroke: am5.color(colour)
        })
      );

      series.columns.template.setAll({
        tooltipText: "{name}: {valueY}",
        tooltipY: am5.percent(10)
      });

      // Width of the column of data within the chart
      series.columns.template.setAll({
        width: am5.percent(60)
      });

      series.bullets.push(function() {
        return am5.Bullet.new(root, {
          sprite: am5.Rectangle.new(root, {
            width: 10,
            height: 10,
            centerX: am5.p50,
            centerY: am5.p50,
            fill: am5.color(colour)
          }),
        });
      });

      // data/text sat within the column
      series.bullets.push(function() {
        return am5.Bullet.new(root, {
          locationX: 0.5,
          locationY: 0.5,
          sprite: am5.Label.new(root, {
            fill: root.interfaceColors.get("alternativeText"),
            centerY: am5.percent(50),
            centerX: am5.percent(50),
            populateText: true
          }),
        });
      });

      series.data.setAll(data);
      series.appear();

      return series;
    }

    createSeries("heartFailure", "Heart failure", "#003865");
    createSeries("myocardialInfarction", "Myocardial infarction", "#68D2DF");
    createSeries("stroke", "Stroke", "#F0AB00");

    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    var legend = chart.children.unshift(
      am5.Legend.new(root, {
        nameField: "name",
        fillField: "color",
        strokeField: "color",
        width: am5.percent(100),
        centerX: am5.percent(50),
        x: am5.percent(55),
        layout: am5.GridLayout.new(root, {
          maxColumns: 3,
          fixedWidthGrid: true
        }),
        paddingLeft: 60,
        paddingBottom: 30
      })
    );

    legend.data.setAll([
      {
        name: "Heart failure",
        color: am5.color("#003865")
      },
      {
        name: "Myocardial infarction",
        color: am5.color("#68D2DF")
      },
      {
        name: "Stroke",
        color: am5.color("#F0AB00")
      }
    ]);

    // Add export menu
    let exporting = am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      filePrefix: `burden-of-cv-complications-in-the-diagnosed-ckd-population-${this.country}`
    });

    this.root = root;
  },
  methods: {
    toTimestamp(year) {
      var newDate = new Date(year);
      return newDate.getTime();
    },
  },
  beforeUnmount() {
    if (this.root) {
      this.root.dispose();
    }
  },
};
</script>

<style scoped>
.chartdiv {
  width: 100%;
  height: 600px;
}
</style>
