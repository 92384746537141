<template>
  <header>
    <div class="breadcrumb-internal-nav-background">
      <Navbar/>
    </div>
  </header>
  <div class="container-xxl">
    <div class="row justify-content-around mb-3">
      <div class="col-12 order-last order-md-first col-md-6">
        <div class="world-map" :class="{largeMap : selectedCountry}" ref="mapdiv"></div>
      </div>
      <div class="col-12 col-md-6">
        <div class="d-flex mt-3 mb-4 pt-5">
          <p class="fst-italic pt-1 explore-label d-none d-sm-block d-flex pe-3 mb-4 pb-4">Country/Region</p>
          <select id="countrySelect" @change="changeCountry()" v-model="selectedCountry" class="form-select explore-dropdown border-0 d-flex" aria-label="1" autocomplete="off">
            <option disabled value="">Select a country</option>
            <option v-for="country in countries" :value="country.id">{{ country.name }}</option>
          </select>
          <img src="/img/yellow-country-dropdown-close-button.svg" class="ms-2 country-close-button" data-bs-dismiss="dropdown" aria-label="Close" @click="resetMapAndDropdown" />
        </div>
        <div class="card px-3 explore-block text-light mt-4 mb-2">
          <div class="card-body">
            <h1 class="card-title py-3 fst-italic text-light display-5">
              {{ selectedCountryName ? selectedCountryName  : 'Inside CKD Data Outputs' }}
            </h1>
            <div class="text-light pb-4">
              <div v-if="selectedCountry">
                <ChartList :slug="selectedCountrySlug" />
              </div>
              <div class="pb-3" v-else>
                Use the interactive map to navigate through the Inside CKD data.<sup>1–4</sup> Click on a country to view the relevant interactive chart
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <p class="footnote"><strong>Citations:</strong><sup>1</sup>Tangri N, Chadban S, Cabrera C, Retat L, Sánchez JJG. Projecting the epidemiological and economic impact of chronic kidney disease using patient-level microsimulation modelling: rationale and methods of Inside CKD. <i>Adv Ther</i> 2023;40:265–81. doi:10.1007/s12325-022-02353-5;<sup>2</sup>Jha V, Al-Ghamdi SMG, Li G, Wu MS, Stafylas P, Retat L, <i>et al</i>. Global economic burden associated with chronic kidney disease: a pragmatic review of medical costs for the Inside CKD research programme. <i>Adv Ther</i> 2023;40:4405–20. doi: 10.1007/s12325-023-02608-9;<sup>3</sup>Chertow G, Correa-Rotter R, Eckardt K et al. Projecting the clinical burden of chronic kidney disease at the patient level (Inside CKD): a microsimulation modelling study. <i>eClinicalMedicine</i> 2024;102614. doi: 10.1016/j.eclinm.2024.102614;<sup>4</sup>Chadban S, Arıcı M, Power A et al. Projecting the economic burden of chronic kidney disease at the patient level (Inside CKD): a microsimulation modelling study. <i>eClinicalMedicine</i> 2024;102615. doi: 10.1016/j.eclinm.2024.102615.</p>
        <p class="footnote"><strong>Abbreviations:</strong> CKD, chronic kidney disease; CV, cardiovascular; KRT, kidney replacement therapy.</p>
      </div>
    </div>
  </div>
  <TopSearchRegions />
</template>

<script>
import TopSearchRegions from "@/components/TopSearchRegions.vue";
import ExploreTheDataNavbar from "@/components/ExploreTheDataNavbar.vue";
import ChartList from "@/components/ChartList.vue";
import Navbar from "@/components/Navbar.vue";
import countries from '../../public/data/countries.json';

import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";

export default {
  name: "ExploreTheData",
  components: {
    ChartList,
    ExploreTheDataNavbar,
    Navbar,
    TopSearchRegions
  },
  data() {
    return {
      chart: null,
      countries: countries,
      selectedCountry: "",
      selectedCountryName: null,
      selectedCountrySlug: null
    };
  },
  async mounted() {
    am5.addLicense("AM5C317022592");
    this.buildMap();
  },
  methods: {
    changeCountry() {
      this.countries.forEach((country) => {
        if (country.id === this.selectedCountry) {
          this.selectedCountrySlug = country.slug;
          this.selectedCountryName = country.name;
          this.zoomToCountry(country);
        }
      });
    },
    zoomToCountry(country) {
      this.chart.zoomToGeoPoint({
        latitude: country.lat,
        longitude: country.lng
      }, country.zoom, true);
    },
    resetMapAndDropdown() {
      this.selectedCountry = "";
      this.selectedCountryName = null;
      this.selectedCountrySlug = null;

      this.reloadMap();
    },
    buildMap() {
      am5.addLicense("AM5M398148298");

      let map = am5.Root.new(this.$refs.mapdiv);

      // Set themes
      // https://www.amcharts.com/docs/v5/concepts/themes/
      map.setThemes([
        am5themes_Animated.new(map)
      ]);

      // Create the map chart
      // https://www.amcharts.com/docs/v5/charts/map-chart/
      this.chart = map.container.children.push(
        am5map.MapChart.new(map, {
          panX: "translateX",
          panY: "translateY",
          projection: am5map.geoNaturalEarth1()
        })
      );

      // Create polygon series for countries
      // https://www.amcharts.com/docs/v5/charts/map-chart/map-polygon-series/
      let mapSeries = this.chart.series.push(
        am5map.MapPolygonSeries.new(map, {
          geoJSON: am5geodata_worldLow,
          exclude: ["AQ"],
          tooltip: am5.Tooltip.new(map, {
            getFillFromSprite: false,
            autoTextColor: false
          })
        })
      );

      // Assign the countries data
      mapSeries.countries = this.countries;

      mapSeries.mapPolygons.template.setAll({
        // toggleKey: "active",
        interactive: true,
        fill: am5.color("#D5D5D5"), // This greys out the countries
        templateField: "settings",
      });

      // Country tooltip background styling
      mapSeries.get("tooltip").get("background").setAll({
        fill: am5.color("#FFFFFF"),
        fillOpacity: 1,
        stroke: am5.color("#143762")
      });

      // Country tooltip text styling
      mapSeries.get("tooltip").label.setAll({
        fill: am5.color("#143762")
      });

      // // todo - only hover on countries that are in the countries array
      // mapSeries.mapPolygons.template.states.create("hover", {
      //   fill: am5.color("#E6AD3A") // todo - this is really slow to take affect
      // });

      // todo - only allow active on countries that are allowed to be clicked
      mapSeries.mapPolygons.template.states.create("active", {
        fill: am5.color("#E6AD3A")
      });

      // Selecting a country
      mapSeries.mapPolygons.template.events.on("click", (event) => {
        // Loop through and the find the country that has been selected
        mapSeries.countries.forEach((country) => {
          if (country.id === event.target.dataItem.dataContext.id) {
            this.selectedCountry = country.id;
            this.selectedCountryName = country.name;
            this.selectedCountrySlug = country.slug;
            this.chart.set("maxZoomLevel", country.zoom);

            return false;
          } else {
            // This fixes the issue when the speed of the highlight, we need to keep it
            event.target.setAll({
              fill: am5.color("#D5D5D5")
            })
          }
        });
      });

      // Only allow zoom to a country that is in our list of countries
      mapSeries.mapPolygons.template.on("active", function (active, target) {
        mapSeries.countries.forEach((country) => {
          if (country.id === target.dataItem._settings.id) {
            mapSeries.zoomToDataItem(target.dataItem);

            return false;
          } else {
            // target.setAll({
            //   fill: am5.color("#D5D5D5")
            // })
          }
        });
      });

      const zoomCtrl = am5map.ZoomControl.new(map, {
        paddingTop: 10,
        paddingBottom: 10,
        marginTop: 10,
        x: am5.percent(2),
        centerX: am5.percent(2),
        y: am5.percent(10),
        centerY: am5.percent(10),
      });

      const { minusButton, plusButton } = zoomCtrl;

      plusButton.get("background").setAll({
        cornerRadiusTL: 10,
        cornerRadiusTR: 10,
        cornerRadiusBR: 10,
        cornerRadiusBL: 10,
        fill: am5.color("#E6AD3A"),
        border: am5.color("#143762")
      });

      plusButton.get("background").states.create("hover", {}).setAll({
        fill: am5.color("#E6AD3A"),
        border: am5.color("#E6AD3A")
      });

      plusButton.get("background").states.create("down", {}).setAll({
        fill: am5.color("#838383"),
        border: am5.color("#838383")
      });

      plusButton.set("cursorOverStyle", "pointer");

      minusButton.get("background").setAll({
        cornerRadiusTL: 10,
        cornerRadiusTR: 10,
        cornerRadiusBR: 10,
        cornerRadiusBL: 10,
        fill: am5.color("#143762"),
        border: am5.color("#143762")
      });

      minusButton.get("background").states.create("hover", {}).setAll({
        fill: am5.color("#E6AD3A"),
        border: am5.color("#E6AD3A")
      });

      minusButton.set("cursorOverStyle", "pointer");

      minusButton.get("background").states.create("down", {}).setAll({
        fill: am5.color("#838383"),
        border: am5.color("#838383")
      });

      // Add zoom control
      // https://www.amcharts.com/docs/v5/charts/map-chart/map-pan-zoom/#Zoom_control
      this.chart.set("zoomControl", zoomCtrl);

      // Set clicking on "water" to zoom out
      // todo - this isn't working
      this.chart.chartContainer.get("background").events.on("click", function () {
        this.chart.goHome();
      });

      mapSeries.data.setAll(this.countries);

      this.root = map;
    },
    reloadMap() {
      // Dispose of first
      if (this.root) {
        this.root.dispose();
        this.chart = null;
      }

      this.buildMap();
    }
  }
};
</script>
