<template>
  <h2 className="pb-4">Total direct costs associated with diagnosed CKD and KRT</h2>
  <div className="chartdiv" ref="chartdiv"></div>
  <div><p><small>Tip: hover the cursor over the graph to show data labels.</small></p></div>
</template>

<script>
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";

export default {
  name: "OverallCosts",
  props: {
    country: String
  },
  async mounted() {
    am5.addLicense("AM5C317022592");

    let root = am5.Root.new(this.$refs.chartdiv);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        layout: root.verticalLayout
      })
    );

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)]);

    let response = await fetch(`/data/overall-costs/${this.country}.json`, {
      method: "GET",
      headers: {
        Accept: "application/json"
      },
    });

    const json = await response.json();

    let data = [];

    json.forEach((element) => {
      data.push({
        year: `${element.year}`,
        totalCkdCosts: element.totalCkdCosts,
        totalKrtCosts: element.totalKrtCosts
      });
    });

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    var xRenderer = am5xy.AxisRendererX.new(root, {
      cellStartLocation: 0.2,
      cellEndLocation: 0.8,
      strokeOpacity: 1,
      strokeWidth: 1
    });

    var xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "year",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {})
      })
    );

    xRenderer.grid.template.setAll({
      location: 1
    });

    xAxis.data.setAll(data);

    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 1,
          strokeWidth: 1
        }),
      })
    );

    yAxis.get("renderer").grid.template.set("forceHidden", true);
    xAxis.get("renderer").grid.template.set("forceHidden", true);

    // chart ticks

    var yRenderer = yAxis.get("renderer");
    yRenderer.ticks.template.setAll({
      stroke: am5.color(0x000000),
      visible: true
    });

    // Removing the comma for the US numbers as they are over a billion in total
    let numberFormat = "####.00";

    if (this.country === "us") {
      numberFormat = "####";
    }

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    function makeSeries(name, fieldName, stacked, colour) {
      var series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          stacked: true,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName,
          categoryXField: "year",
          fill: am5.color(colour)
        })
      );

      // Format number so it shows two decimal places even with a zero on the end
      root.numberFormatter.setAll({
        // numberFormat: "#,##.00",
        numberFOrmat: numberFormat,
        numericFields: ["valueY"]
      });

      series.columns.template.setAll({
        tooltipText: "{name}: {valueY}",
        tooltipY: am5.percent(10),
      });

      if (stacked) {
        series.columns.template.setAll({
          width: am5.percent(100)
        });
        series.bullets.push(function() {
          return am5.Bullet.new(root, {
            locationY: 0.5,
            locationX: 0.5,
            sprite: am5.Label.new(root, {
              fill: root.interfaceColors.get("alternativeText"),
              centerY: am5.percent(50),
              centerX: am5.percent(50),
              populateText: true
            }),
          });
        });
      } else {
        series.columns.template.setAll({
          width: am5.percent(100)
        });
      }

      series.data.setAll(data);
    }

    makeSeries("CKD", "totalCkdCosts", true, "#003865");
    makeSeries("KRT", "totalKrtCosts", true, "#F0AB00");

    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    var legend = chart.children.unshift(
      am5.Legend.new(root, {
        nameField: "name",
        fillField: "color",
        strokeField: "color",
        centerX: am5.percent(50),
        x: am5.percent(55),
        paddingBottom: 20
      })
    );

    legend.data.setAll([
      {
        name: "CKD",
        color: am5.color("#003865")
      },
      {
        name: "KRT",
        color: am5.color("#F0AB00")
      }
    ]);

    yAxis.children.unshift(
      am5.Label.new(root, {
        rotation: 270,
        text: "Cost (billions, US$)",
        x: am5.p50,
        centerX: am5.p50,
        y: am5.p50,
        centerY: am5.p50,
        fontWeight: "700",
        paddingBottom: 12
      })
    );

    // Add export menu
    let exporting = am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      filePrefix: `overall-costs-${this.country}`
    });

    this.root = root;
  },

  beforeUnmount() {
    if (this.root) {
      this.root.dispose();
    }
  },
};
</script>

<style scoped>
.chartdiv {
  width: 100%;
  height: 500px;
}
</style>
