<template>
  <h2 class="mt-2 pt-4 pb-4">Direct costs associated with diagnosed CKD by stage (pre-KRT)</h2>
  <div class="legenddiv" ref="legenddiv"></div>
  <div class="chartdiv" ref="chartdiv"></div>
  <div><p><small>Tip: hover the cursor over the graph to show data labels.</small></p></div>
</template>

<script>
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
export default {
  name: "CkdCostsByStage",
  props: {
    country: String,
  },
  async mounted() {
    am5.addLicense("AM5C317022592");

    let root = am5.Root.new(this.$refs.chartdiv);
    var legendRoot = am5.Root.new(this.$refs.legenddiv);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        layout: root.verticalLayout
      })
    );

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)]);

    let response = await fetch(
      `/data/ckd-costs-by-stage/${this.country}.json`,
      {
        method: "GET",
        headers: {
          Accept: "application/json"
        },
      }
    );

    const json = await response.json();

    let data = [];

    json.forEach((element) => {
      data.push({
        year: `${element.year}`,
        stage3A: element.stage3A,
        stage3B: element.stage3B,
        stage4: element.stage4,
        stage5: element.stage5
      });
    });

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    var xRenderer = am5xy.AxisRendererX.new(root, {
      cellStartLocation: 0.2,
      cellEndLocation: 0.8,
      strokeOpacity: 1,
      strokeWidth: 1
    });

    var xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "year",
        renderer: xRenderer
      })
    );

    xRenderer.grid.template.setAll({
      location: 1
    });

    xAxis.data.setAll(data);

    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 1,
          strokeWidth: 1
        }),
      })
    );

    yAxis.get("renderer").grid.template.set("forceHidden", true);
    xAxis.get("renderer").grid.template.set("forceHidden", true);

    // chart ticks

    var yRenderer = yAxis.get("renderer");
    yRenderer.ticks.template.setAll({
      stroke: am5.color(0x000000),
      visible: true
    });

    // Removing the comma for the US numbers as they are over a billion in total
    let numberFormat = "####.00";

    if (this.country === "us") {
      numberFormat = "####";
    }

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    function makeSeries(name, fieldName, stacked, colour) {
      var series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          stacked: true,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName,
          categoryXField: "year",
          fill: am5.color(colour)
        })
      );

      // Format number so it shows two decimal places even with a zero on the end
      root.numberFormatter.setAll({
        numberFormat: numberFormat,
        numericFields: ["valueY"]
      });

      series.columns.template.setAll({
        tooltipText: "{name}: {valueY}",
        tooltipY: am5.percent(10)
      });

      if (stacked) {
        series.columns.template.setAll({
          width: am5.percent(100)
        });
        series.bullets.push(function() {
          return am5.Bullet.new(root, {
            locationY: 0.5,
            locationX: 0.5,
            sprite: am5.Label.new(root, {
              // text: "{valueY}m",
              fill: root.interfaceColors.get("alternativeText"),
              centerY: am5.percent(50),
              centerX: am5.percent(50),
              populateText: true
            }),
          });
        });
      } else {
        series.columns.template.setAll({
          width: am5.percent(100)
        });

        let tooltip = series.set(
          "tooltip",
          am5.Tooltip.new(root, {
            getFillFromSprite: false,
            getStrokeFromSprite: true,
            autoTextColor: false,
            pointerOrientation: "horizontal"
          })
        );

        tooltip.get("background").setAll({
          fill: am5.color(0xffffff)
        });

        tooltip.label.setAll({
          text: "[bold]{categoryX}[/]\n",
          fill: am5.color(0x000000)
        });

        tooltip.label.adapters.add("text", function(text) {
          chart.series.each(function(series) {
            if (series.get("name") !== "ckd_stage_3a") {
              text += "\n";
            }

            let colour = "#003865";

            if (series.get("name") === "ckd_stage_3a") {
              colour = "#68D2DF";
            } else if (series.get("name") === "ckd_stage_3b") {
              colour = "#00ADD0";
            } else if (series.get("name") === "ckd_stage_4") {
              colour = "#F0AB00";
            } else if (series.get("name") === "ckd_stage_5") {
              colour = "#003865";
            }

            text +=
              "[" +
              colour +
              "]●[/] [bold width:60px]" +
              series.get("name") +
              ":[/] {" +
              series.get("valueYField") +
              "}m";
          });
          return text;
        });
      }

      series.data.setAll(data);
    }

    makeSeries("CKD stage 3a", "stage3A", true, "#68D2DF");
    makeSeries("CKD stage 3b", "stage3B", true,"#00ADD0");
    makeSeries("CKD stage 4", "stage4", true,"#F0AB00");
    makeSeries("CKD stage 5", "stage5", true,"#003865");

    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    var legend = legendRoot.container.children.unshift(
      am5.Legend.new(legendRoot, {
        nameField: "name",
        fillField: "color",
        strokeField: "color",
        width: am5.percent(100),
        centerX: am5.percent(50),
        x: am5.percent(55),
        paddingLeft: 80,
        layout: am5.GridLayout.new(root, {
          maxColumns: 3,
          fixedWidthGrid: true
        })
      }),

    );

    legend.data.setAll([
      {
        name: "CKD stage 3a",
        color: am5.color("#68D2DF")
      },
      {
        name: "CKD stage 3b",
        color: am5.color("#00ADD0")
      },
      {
        name: "CKD stage 4",
        color: am5.color("#F0AB00")
      },
      {
        name: "CKD stage 5 pre-KRT",
        color: am5.color("#003865")
      }
    ]);

    yAxis.children.unshift(
      am5.Label.new(root, {
        rotation: 270,
        text: "Costs (billions, US$)",
        x: am5.p50,
        centerX: am5.p50,
        y: am5.p50,
        centerY: am5.p50,
        fontWeight: "700",
        fontSize: 14,
        paddingBottom: 12
      })
    );

    // Add export menu
    let exporting = am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      filePrefix: `ckd-costs-by-stage${this.country}`
    });

    this.root = root;
  },
  methods: {
    toTimestamp(year) {
      var newDate = new Date(year);
      return newDate.getTime();
    },
  },
  beforeUnmount() {
    if (this.root) {
      this.root.dispose();
    }
  },
};
</script>

<style scoped>
.chartdiv {
  width: 100%;
  height: 500px;
}
.legenddiv {
  width: 100%;
  height: 90px;
}

@media screen and (max-width: 623px) {
  .legenddiv {
    height: 120px;
  }
}
</style>
