<template>
  <InternalPageHeader title="View the Inside CKD" title-last-word="Publications"/>
  <div class="publications">
    <div class="container-xxl mt-4">
      <div class="row justify-content-center">
        <div class="col-12 px-2">
          <p class="text-center">The Inside CKD programme was initiated and is funded by AstraZeneca. Inside CKD is designed to project the prevalence and burden of chronic kidney disease (CKD). The programme incorporates the use of microsimulation modelling that was conducted by HealthLumen Ltd. Oversight of the programme was provided by a Scientific Steering Committee comprising 32 members. Use the links below to explore the Inside CKD publications. Please refer directly to the publications in order to cite this work.</p>
        </div>
      </div>
      <div class="row justify-content-center">
        <!-- publication 1 -->
        <div class="row">
          <div class="mb-5 pb-5 ps-0 pe-0">
            <div class="homepage-icons">
              <a href="https://doi.org/10.1007/s12325-022-02353-5" id="doi_023535" target="_blank" class="col-12 col-xl-6 mt-xl-0 publication_link_">
                <img src="img/publications-icon.png" alt="solutions icon showing a light bulb" class="img-responsive" />
                <div class="homepage-icon-text">
                  <h3 class="text-center homepage-icon-header publication-title ps-3 pe-3">Projecting the Epidemiological and Economic Impact of Chronic Kidney Disease Using Patient-Level Microsimulation Modelling: Rationale and Methods of Inside CKD</h3>
                  <p class="text-center ps-3 pe-3 publication-text">Click here to explore the <span class="bold-inline">rationale and methods</span> of the Inside CKD microsimulation</p>
                </div>
              </a>
            </div>
          </div>
        </div>
        <!-- Publication 2 -->
        <div class="row">
          <div class="mb-5 pb-5 ps-0 pe-0">
            <div class="homepage-icons">
              <a href="https://doi.org/10.1016/j.eclinm.2024.102614" id="doi_102614" target="_blank" class="col-12 col-xl-6 mt-xl-0 publication_link_">
                <img src="img/publications-icon.png" alt="solutions icon showing a light bulb" class="img-responsive" />
                <div class="homepage-icon-text">
                  <h3 class="text-center homepage-icon-header publication-title ps-3 pe-3">Projecting the clinical burden of chronic kidney disease at the patient level (Inside CKD): a microsimulation modelling study</h3>
                  <p class="text-center ps-3 pe-3 publication-text">Click here to explore the <span class="bold-inline">global clinical burden of CKD</span> including the prevalence of associated comorbidities, KRT and cardiovascular complications of CKD.</p>
                </div>
              </a>
            </div>
          </div>
        </div>
        <!-- Publication 3 -->
        <div class="row">
          <div class="mb-5 pb-5 ps-0 pe-0">
            <div class="homepage-icons">
              <a href="https://doi.org/10.1016/j.eclinm.2024.102615" id="doi_102615" target="_blank" class="col-12 col-xl-6 mt-xl-0 publication_link_">
                <img src="img/publications-icon.png" alt="solutions icon showing a light bulb" class="img-responsive" />
                <div class="homepage-icon-text">
                  <h3 class="text-center homepage-icon-header publication-title ps-3 pe-3">Projecting the economic burden of chronic kidney disease at the patient level (Inside CKD): a microsimulation modelling study</h3>
                  <p class="text-center ps-3 pe-3 publication-text">Click here to explore the <span class="bold-inline">global economic burden of CKD</span>, outlining the projected overall increase in cost of CKD and KRT, including the associated costs of comorbidities and cardiovascular complications.</p>
                </div>
              </a>
            </div>
          </div>
        </div>
        <!-- Publication 4 -->
        <div class="row">
          <div class="mb-5 pb-5 ps-0 pe-0">
            <div class="homepage-icons">
              <a href="https://doi.org/10.1007/s12325-023-02608-9" id="doi_026089" target="_blank" class="col-12 col-xl-6 mt-xl-0 publication_link_">
                <img src="img/publications-icon.png" alt="solutions icon showing a light bulb" class="img-responsive" />
                <div class="homepage-icon-text">
                  <h3 class="text-center homepage-icon-header publication-title ps-3 pe-3">Global Economic Burden Associated with Chronic Kidney Disease: A Pragmatic Review of Medical Costs for the Inside CKD Research Programme</h3>
                  <p class="text-center ps-3 pe-3 publication-text">Click here to explore a <span class="bold-inline">pragmatic review of costing inputs</span> that informed the economics module of the Inside CKD microsimulation and to explore the <span class="bold-inline">per-patient cost projections of CKD</span> management and disease-associated complications.</p>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="col-11 mt-5">
          <p class="footnote pt-5"><strong>Abbreviations:</strong> CKD, chronic kidney disease; KRT, kidney replacement therapy.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InternalPageHeader from "@/components/InternalPageHeader.vue";

export default {
  name: "PublicationsBlock",
  components: { InternalPageHeader }
};

</script>
