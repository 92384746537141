<template>
  <ul class="country-box-text">
    <li class="d-flex explore-list-background rounded align-items-center mb-3">
      <div class="statement-num"></div>
      <a class="flex-grow-1 ps-1" :href="'/explore-the-data/' + slug + '/population-demographics'">Population demographics: drivers of CKD burden</a>
      <img class="chevron" src="/img/chevron-right.png"/>
    </li>
    <li class="d-flex explore-list-background rounded align-items-center mb-3">
      <div class="statement-num"></div>
      <a class="flex-grow-1 ps-1" :href="'/explore-the-data/' + slug + '/prevalence-of-diagnosed-and-undiagnosed-ckd'">Prevalence of diagnosed and undiagnosed CKD</a>
      <img class="chevron" src="/img/chevron-right.png"/>
    </li>
    <li class="d-flex explore-list-background rounded align-items-center mb-3">
      <div class="statement-num"></div>
      <a class="flex-grow-1 ps-1" :href="'/explore-the-data/' + slug + '/prevalence-of-ckd-by-stage'">Prevalence of CKD by stage</a>
      <img class="chevron" src="/img/chevron-right.png"/>
    </li>
    <li class="d-flex explore-list-background rounded align-items-center mb-3">
      <div class="statement-num"></div>
      <a class="flex-grow-1 ps-1" :href="'/explore-the-data/' + slug + '/prevalence-of-krt'">Prevalence of KRT by treatment modality</a>
      <img class="chevron" src="/img/chevron-right.png"/>
    </li>
    <li class="d-flex explore-list-background rounded align-items-center mb-3">
      <div class="statement-num"></div>
      <a class="flex-grow-1 ps-1" :href="'/explore-the-data/' + slug + '/healthcare-costs-associated-with-ckd'">Healthcare costs associated with diagnosed CKD</a>
      <img class="chevron" src="/img/chevron-right.png"/>
    </li>
    <li class="d-flex explore-list-background rounded align-items-center mb-3">
      <div class="statement-num"></div>
      <a class="flex-grow-1 ps-1" :href="'/explore-the-data/' + slug + '/ckd-as-a-proportion-of-healthcare-expenditure'">CKD and KRT costs as a proportion of national annual healthcare expenditure</a>
      <img class="chevron" src="/img/chevron-right.png"/>
    </li>
    <li class="d-flex explore-list-background rounded align-items-center mb-3">
      <div class="statement-num"></div>
      <a class="flex-grow-1 ps-1" :href="'/explore-the-data/' + slug + '/burden-of-cv-complications-in-the-diagnosed-ckd-population'">Cumulative burden of CV complications in the diagnosed CKD population</a>
      <img class="chevron" src="/img/chevron-right.png"/>
    </li>
    <li class="d-flex explore-list-background rounded align-items-center mb-3">
      <div class="statement-num"></div>
      <a class="flex-grow-1 ps-1" :href="'/explore-the-data/' + slug + '/deaths-in-the-ckd-population'">Cumulative number of all-cause deaths in the CKD population</a>
      <img class="chevron" src="/img/chevron-right.png"/>
    </li>
  </ul>
</template>

<script>
export default {
  name: "ChartList",
  props: {
    slug: String
  }
};
</script>
