<template>
  <h2 class="p-3">Population demographics: drivers of CKD burden</h2>
  <div class="chartdiv" ref="chartdiv"></div>
  <div class="p-3"><p><small>Tip: hover the cursor over the graph to show data labels.</small></p></div>
</template>

<script>
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
export default {
  name: "ClusteredBar",
  props: {
    country: String
  },
  async mounted() {
    am5.addLicense("AM5C317022592");

    let root = am5.Root.new(this.$refs.chartdiv);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        layout: root.verticalLayout
      }),
    );

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)]);

    let response = await fetch(`/data/population-demographics/${this.country}.json`, {
      method: "GET",
      headers: {
        Accept: "application/json"
      },
    });

    const json = await response.json();

    // Data
    let data = [
      {
        yAxisLabel: "% of population aged ≥ 65",
        country: json[0].percentageOfPopulationAged65,
        highIncome: 17.4043,
        middleIncome: 9.825
      },
      {
        yAxisLabel: "Coronary heart disease",
        country: json[0].coronaryHeartDisease,
        highIncome: 3.5043,
        middleIncome: 2.9
      },
      {
        yAxisLabel: "Diabetes",
        country: json[0].diabetes,
        highIncome: 7.7348,
        middleIncome: 9.825
      },
      {
        yAxisLabel: "Hypertension",
        country: json[0].hypertension,
        highIncome: 19.313,
        middleIncome: 22.5875
      },
      {
        yAxisLabel: "Obesity",
        country: json[0].obesity,
        highIncome: 22.1,
        middleIncome: 18.05
      },
      {
        yAxisLabel: "Smoking",
        country: json[0].smoking,
        highIncome: 23.7522,
        middleIncome: 21.1375
      },
      {
        yAxisLabel: "Stroke",
        country: json[0].stroke,
        highIncome: 1.6087,
        middleIncome: 1.4125
      },
    ];

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    var yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "yAxisLabel",
        renderer: am5xy.AxisRendererY.new(root, {
          inversed: true,
          cellStartLocation: 0.1,
          cellEndLocation: 0.9,
          strokeOpacity: 1,
          strokeWidth: 1
        }),
      })
    );

    var yRenderer = yAxis.get("renderer");
    yRenderer.ticks.template.setAll({
      stroke: am5.color(0x000000),
      visible: true
    });

    yAxis.data.setAll(data);
    yAxis.get("renderer").grid.template.set("forceHidden", true);

    var xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererX.new(root, {
          strokeOpacity: 1,
          strokeWidth: 1
        }),
        min: 0
      })
    );

    var xRenderer = xAxis.get("renderer");
    xRenderer.ticks.template.setAll({
      stroke: am5.color(0x000000),
      visible: true
    });

    xAxis.children.push(
      am5.Label.new(root, {
        text: "Proportion of population (%)",
        x: am5.p50,
        centerX: am5.p50
      })
    );

    xAxis.get("renderer").grid.template.set("forceHidden", true);

    // Set number format on tooltip to one decimal place
    root.numberFormatter.setAll({
      numberFormat: "#,###.0",
      numericFields: ["valueY"]
    });

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    function createSeries(field, name, colour, showTooltip = false) {
      var series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueXField: field,
          categoryYField: "yAxisLabel",
          sequencedInterpolation: true,
          stroke: am5.color(colour)
        })
      );

      series.columns.template.setAll({
        height: am5.p100,
        strokeOpacity: 0
      });

      series.set("fill", am5.color(colour));

      if (showTooltip) {
        var tooltip = series.set(
          "tooltip",
          am5.Tooltip.new(root, {
            getFillFromSprite: false,
            getStrokeFromSprite: true,
            autoTextColor: false,
            pointerOrientation: "horizontal"
          })
        );

        tooltip.get("background").setAll({
          fill: am5.color(0xffffff)
        });

        tooltip.label.setAll({
          text: "[bold]{categoryX}[/]",
          fill: am5.color(0x000000)
        });

        tooltip.label.adapters.add("text", function (text) {
          chart.series.each(function (series) {
            if (series.get("name") === json[0].country) {
              text += "[bold width:140px]{categoryY}[/]\n\n";
            } else {
              text += "\n";
            }

            text +=
              "[" +
              series.get("stroke").toString() +
              "]●[/] [bold width:140px]" +
              series.get("name") +
              ":[/] {" +
              series.get("valueXField") +
              "}";
          });
          return text;
        });
      }

      series.data.setAll(data);
      series.appear();

      return series;
    }

    createSeries("country", json[0].country, "#003865");
    createSeries("highIncome", "High income", "#F0AB00");
    createSeries("middleIncome", "Middle income", "#3F4444", true);

    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    var legend = chart.children.unshift(
      am5.Legend.new(root, {
        width: am5.percent(100),
        centerX: am5.percent(50),
        x: am5.percent(60),
        nameField: "name",
        fillField: "color",
        strokeField: "color",
        layout: am5.GridLayout.new(root, {
          maxColumns: 3,
          fixedWidthGrid: true
        }),
        paddingBottom: 35
      })
    );

    legend.data.setAll([
      {
        name: json[0].country,
        color: am5.color("#003865")
      },
      {
        name: "High income",
        color: am5.color("#F0AB00")
      },
      {
        name: "Middle income",
        color: am5.color("#3F4444")
      }
    ]);

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    var cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "zoomY",
      })
    );
    cursor.lineY.set("forceHidden", true);
    cursor.lineX.set("forceHidden", true);

    chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        settings: {
          strokeWidth: 4
        },
      })
    );

    // Add export menu
    let exporting = am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      filePrefix: `population-demographics-${this.country}`
    });

    this.root = root;
  },

  beforeUnmount() {
    if (this.root) {
      this.root.dispose();
    }
  },
};
</script>

<style scoped>
.chartdiv {
  width: 100%;
  height: 650px;
}
</style>
