<template>
  <!-- mobile carousel -->
  <section class="grey-block-bg d-xl-none">
    <div class="container-fluid pt-3 pb-5">
      <div class="text-center pt-5 pb-3">
        <h1 class="fst-italic pb-2 display-4">Top searched regions</h1>
      </div>
      <div class="row mx-3">
        <div class="col-12">
          <div id="exploreDataCarouselMobile" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active" data-bs-interval="5000">
                <div class="cards-wrapper justify-content-center">
                  <div class="card col-12 mx-3 my-3 pb-3 align-items-center border-0">
                    <h4 class="pt-5">US</h4>
                    <img src="/img/usa.svg" class="card-img-fluid country-image px-4" alt="Solid yellow map image of the Unites States of America" />
                    <div class="card-body mt-3">
                      <router-link to="/explore-the-data/us" class="top-search-buttons">Select</router-link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item" data-bs-interval="5000">
                <div class="cards-wrapper justify-content-center">
                  <div class="card col-12 mx-3 my-3 pb-3 align-items-center border-0">
                    <h4 class="pt-5">UK</h4>
                    <img src="/img/uk.svg" class="card-img-fluid country-image px-4" alt="Solid yellow map image of the UK" />
                    <div class="card-body mt-3">
                      <router-link to="/explore-the-data/uk" class="top-search-buttons">Select</router-link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="cards-wrapper justify-content-center">
                  <div class="card col-12 mx-3 my-3 pb-3 align-items-center border-0">
                    <h4 class="pt-5">France</h4>
                    <img src="/img/france.svg" class="card-img-fluid country-image px-4" alt="Solid yellow map image of France" />
                    <div class="card-body mt-3">
                      <router-link to="/explore-the-data/france" class="top-search-buttons">Select</router-link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="cards-wrapper justify-content-center">
                  <div class="card col-12 mx-3 my-3 pb-3 align-items-center border-0">
                    <h4 class="pt-5">Italy</h4>
                    <img src="/img/italy.svg" class="card-img-fluid country-image px-4" alt="Solid yellow map image of Italy" />
                    <div class="card-body mt-3">
                      <router-link to="/explore-the-data/italy" class="top-search-buttons">Select</router-link>
                    </div>
                  </div>
                </div>
                </div>
                <div class="carousel-item">
                  <div class="cards-wrapper justify-content-center">
                    <div class="card col-12 mx-3 my-3 pb-3 align-items-center border-0">
                      <h4 class="pt-5">Spain</h4>
                      <img src="/img/spain.svg" class="card-img-fluid country-image px-4" alt="Solid yellow map image of Spain" />
                      <div class="card-body mt-3">
                        <router-link to="/explore-the-data/spain" class="top-search-buttons">Select</router-link>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="carousel-item" data-bs-interval="5000">
                <div class="cards-wrapper justify-content-center">
                  <div class="card col-12 mx-3 my-3 pb-3 align-items-center border-0">
                    <h4 class="pt-5">Denmark</h4>
                    <img src="/img/denmark.svg" class="card-img-fluid country-image px-4" alt="Solid yellow map image of Denmark" />
                    <div class="card-body mt-3">
                      <router-link to="/explore-the-data/denmark" class="top-search-buttons">Select</router-link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="cards-wrapper justify-content-center">
                  <div class="card col-12 mx-3 my-3 pb-3 align-items-center border-0">
                    <h4 class="pt-5">China</h4>
                    <img src="/img/china.svg" class="card-img-fluid country-image px-4" alt="Solid yellow map image of China" />
                    <div class="card-body mt-3">
                      <router-link to="/explore-the-data/china" class="top-search-buttons">Select</router-link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="cards-wrapper justify-content-center">
                  <div class="card col-12 mx-3 my-3 pb-3 align-items-center border-0">
                    <h4 class="pt-5">Japan</h4>
                    <img src="/img/japan.svg" class="card-img-fluid country-image px-4" alt="Solid yellow map image of Japan" />
                    <div class="card-body mt-3">
                      <router-link to="/explore-the-data/japan" class="top-search-buttons">Select</router-link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="cards-wrapper justify-content-center">
                  <div class="card col-12 mx-3 my-3 pb-3 align-items-center border-0">
                    <h4 class="pt-5">Brazil</h4>
                    <img src="/img/brazil.svg" class="card-img-fluid country-image px-4" alt="Solid yellow map image of Brazil" />
                    <div class="card-body mt-3">
                      <router-link to="/explore-the-data/brazil" class="top-search-buttons">Select</router-link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="cards-wrapper justify-content-center">
                  <div class="card col-12 mx-3 my-3 pb-3 align-items-center border-0">
                    <h4 class="pt-5">Türkiye</h4>
                    <img src="/img/turkey.svg" class="card-img-fluid country-image px-5" alt="Solid yellow map image of Turkey" />
                    <div class="card-body mt-3">
                      <router-link to="/explore-the-data/turkey" class="top-search-buttons">Select</router-link>
                    </div>
                  </div>
                </div>
              </div>
              <button class="carousel-control-prev" type="button" data-bs-target="#exploreDataCarouselMobile" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button class="carousel-control-next" type="button" data-bs-target="#exploreDataCarouselMobile" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- desktop carousel -->
  <section class="grey-block-bg d-none d-xl-block mt-4">
    <div class="container-fluid pt-3 pb-5">
      <div class="text-center pt-5 pb-3">
        <h1 class="fst-italic pb-2 display-4">Top searched regions</h1>
      </div>
      <div id="exploreDataCarouselDesktop" class="carousel carousel-dark slide" data-bs-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active" data-bs-interval="8000">
            <div class="cards-wrapper row justify-content-center">
              <div class="card col-sm-6 col-md-4 col-xl-2 align-items-center mx-3 my-3 pt-2 pb-3 align-items-center border-0">
                <h4 class="pt-5">US</h4>
                <img src="/img/usa.svg" class="img-fluid country-image px-4" alt="Solid yellow map image of the US" />
                <div class="card-body mt-3">
                  <router-link to="/explore-the-data/us" class="top-search-buttons">Select</router-link>
                </div>
              </div>
              <div class="card col-sm-6 col-md-4 col-xl-2 align-items-center mx-3 my-3 pt-2 pb-3 align-items-center border-0">
                <h4 class="pt-5 uk-title-sorting">UK</h4>
                <img src="/img/uk.svg" class="img-fluid country-image px-4" alt="Solid yellow map image of the UK" />
                <div class="card-body mt-3">
                  <router-link to="/explore-the-data/uk" class="top-search-buttons">Select</router-link>
                </div>
              </div>
              <div class="card col-sm-6 col-md-4 col-xl-2 align-items-center mx-3 my-3 pt-2 pb-3 align-items-center border-0">
                <h4 class="pt-5">France</h4>
                <img src="/img/france.svg" class="img-fluid country-image px-4" alt="Solid yellow map image of France" />
                <div class="card-body mt-3">
                  <router-link to="/explore-the-data/france" class="top-search-buttons">Select</router-link>
                </div>
              </div>
              <div class="card col-sm-6 col-md-4 col-xl-2 align-items-center mx-3 my-3 pt-2 pb-3 align-items-center border-0">
                <h4 class="pt-5">Italy</h4>
                <img src="/img/italy.svg" class="img-fluid country-image px-4" alt="Solid yellow map image of Italy" />
                <div class="card-body mt-3">
                  <router-link to="/explore-the-data/italy" class="top-search-buttons">Select</router-link>
                </div>
              </div>
              <div class="card col-sm-6 col-md-4 col-xl-2 align-items-center mx-3 my-3 pt-2 pb-3 align-items-center border-0">
                <h4 class="pt-5">Spain</h4>
                <img src="/img/spain.svg" class="img-fluid country-image px-4" alt="Solid yellow map image of Spain" />
                <div class="card-body mt-3">
                  <router-link to="/explore-the-data/spain" class="top-search-buttons">Select</router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item" data-bs-interval="8000">
            <div class="cards-wrapper row justify-content-center">
              <div class="card col-sm-6 col-md-4 col-xl-2 align-items-center mx-3 my-3 pt-2 pb-3 align-items-center border-0">
                <h4 class="pt-5">Denmark</h4>
                <img src="/img/denmark.svg" class="img-fluid country-image px-4" alt="Solid yellow map image of Denmark" />
                <div class="card-body mt-3">
                  <router-link to="/explore-the-data/denmark" class="top-search-buttons">Select</router-link>
                </div>
              </div>
              <div class="card col-sm-6 col-md-4 col-xl-2 align-items-center mx-3 my-3 pt-2 pb-3 align-items-center border-0">
                <h4 class="pt-5">China</h4>
                <img src="/img/china.svg" class="img-fluid country-image px-4" alt="Solid yellow map image of China" />
                <div class="card-body mt-3">
                  <router-link to="/explore-the-data/china" class="top-search-buttons">Select</router-link>
                </div>
              </div>
              <div class="card col-sm-6 col-md-4 col-xl-2 align-items-center mx-3 my-3 pt-2 pb-3 align-items-center border-0">
                <h4 class="pt-5">Japan</h4>
                <img src="/img/japan.svg" class="img-fluid country-image px-4" alt="Solid yellow map image of Japan" />
                <div class="card-body mt-3">
                  <router-link to="/explore-the-data/japan" class="top-search-buttons">Select</router-link>
                </div>
              </div>
              <div class="card col-sm-6 col-md-4 col-xl-2 align-items-center mx-3 my-3 pt-2 pb-3 align-items-center border-0">
                <h4 class="pt-5">Brazil</h4>
                <img src="/img/brazil.svg" class="img-fluid country-image px-4" alt="Solid yellow map image of Brazil" />
                <div class="card-body mt-3">
                  <router-link to="/explore-the-data/brazil" class="top-search-buttons">Select</router-link>
                </div>
              </div>
              <div class="card col-sm-6 col-md-4 col-xl-2 align-items-center mx-3 my-3 pt-2 pb-3 align-items-center border-0">
                <h4 class="pt-5">Türkiye</h4>
                <img src="/img/turkey.svg" class="img-fluid country-image px-4" alt="Solid yellow map image of Turkey" />
                <div class="card-body mt-3">
                  <router-link to="/explore-the-data/turkey" class="top-search-buttons">Select</router-link>
                </div>
              </div>
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#exploreDataCarouselDesktop" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#exploreDataCarouselDesktop" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "TopSearchRegions"
};
</script>
