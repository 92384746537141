<template>
  <h2 class="p-3">CKD and KRT costs as a proportion of national annual healthcare expenditure</h2>
  <div class="legenddiv" ref="legenddiv"></div>
  <div class="chartdiv" ref="chartdiv"></div>
  <div class="p-3"><p><small>Tip: hover the cursor over the graph to show data labels.</small></p></div>

</template>

<script>
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
export default {
  name: "CkdAsAProportionOfHealthcareExpenditure",
  props: {
    country: String,
  },
  async mounted() {
    am5.addLicense("AM5C317022592");

    let root = am5.Root.new(this.$refs.chartdiv);
    var legendRoot = am5.Root.new(this.$refs.legenddiv);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        pinchZoomX: true
      })
    );

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)]);

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    var cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "none"
      })
    );
    cursor.lineY.set("visible", false);

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    var xAxis = chart.xAxes.push(
      am5xy.GaplessDateAxis.new(root, {
        maxDeviation: 0.2,
        baseInterval: {
          timeUnit: "year",
          count: 1
        },
        renderer: am5xy.AxisRendererX.new(root, {
          strokeOpacity: 1,
          strokeWidth: 1,
          minGridDistance: 5
        }),
      })
    );

    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 1,
          strokeWidth: 1
        }),
        numberFormat: "#.0"
      })
    );

    // chart ticks

    var yRenderer = yAxis.get("renderer");
    yRenderer.ticks.template.setAll({
      stroke: am5.color(0x000000),
      visible: true
    });


    yAxis.get("renderer").grid.template.set("forceHidden", true);
    xAxis.get("renderer").grid.template.set("forceHidden", true);

    let response = await fetch(`/data/ckd-as-a-proportion-of-healthcare-expenditure/${this.country}.json`, {
      method: "GET",
      headers: {
        Accept: "application/json"
      },
    });

    const json = await response.json();

    let data = [];

    json.forEach((element) => {
      data.push({
        year: this.toTimestamp(`${element.year}`),
        Total: element.sum,
        CKD: element.sumCkd,
        KRT: element.sumKrt
      });
    });

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    function createSeries(field, name, colour) {
      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
      var series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: field,
          valueXField: "year",
          fill: am5.color(colour),
          stroke: am5.color(colour),
          tooltip: am5.Tooltip.new(root, {
            labelText: "[bold]{valueX}[/]\n{name}: {valueY}%"
          })
        })
      );

      // Makes the other two lines (CKD and KRT) dashed - keeping the "Total" line solid
      if(field !== "Total") {
        series.strokes.template.setAll({
          strokeWidth: 4,
          strokeDasharray: [8,4]
        });
      } else {
        series.strokes.template.setAll({
          strokeWidth: 4
        });
      }

      series.data.setAll(data);
      series.appear();

      return series;
    }

    createSeries("Total", "Total", "#3F4444");
    createSeries("CKD","CKD", "#003865");
    createSeries("KRT","KRT", "#F0AB00");

    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    var legend = legendRoot.container.children.unshift(
      am5.Legend.new(legendRoot, {
        nameField: "name",
        fillField: "color",
        strokeField: "color",
        width: am5.percent(100),
        centerX: am5.percent(40),
        x: am5.percent(55),
        paddingLeft: 60,
        layout: am5.GridLayout.new(root, {
          maxColumns: 3,
          fixedWidthGrid: true
        })
      }),
    );

    legend.data.setAll([
      {
        name: "Total",
        color: am5.color("#3F4444")
      },
      {
        name: "CKD",
        color: am5.color("#003865")
      },
      {
        name: "KRT",
        color: am5.color("#F0AB00")
      }
    ]);

    // Format number so it shows two decimal places even with a zero on the end
    root.numberFormatter.setAll({
      numberFormat: "#,##.00",
      numericFields: ["valueY"]
    });

    root.dateFormatter.setAll({
      dateFormat: "yyyy",
      dateFields: ["valueX"]
    });

    yAxis.children.unshift(
      am5.Label.new(root, {
        rotation: 270,
        text: "Share of healthcare expenditure (%)",
        x: am5.p50,
        centerX: am5.p50,
        y: am5.p50,
        centerY: am5.p50,
        fontWeight: "500",
        fontSize: 14,
        paddingBottom: 12
      })
    );

    // xAxis.get("renderer").grid.template.set("forceHidden", true);
    // yAxis.get("renderer").grid.template.set("forceHidden", true);

    // Add export menu
    let exporting = am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      filePrefix: `ckd-as-a-proportion-of-healthcare-expenditure-${this.country}`
    });

    this.root = root;
  },
  methods: {
    toTimestamp(year) {
      var newDate = new Date(year);
      return newDate.getTime();
    },
  },
  beforeUnmount() {
    if (this.root) {
      this.root.dispose();
    }
  },
};
</script>

<style scoped>
.chartdiv {
  width: 100%;
  height: 500px;
}

.legenddiv {
  width: 100%;
  height: 90px;
}
</style>
